import React from "react";
import { Button, Grid } from "@material-ui/core";
import NewResellerInvite from "components/utils/NewResellerInvite";
import { Link } from "react-router-dom";
import NewDemoButton from "components/utils/NewDemoButton";

const RepToolbox = () => {

    return (
        <Grid item xs={12} className="overview-card" key={"rep-toolbox-card"} style={{height: "100%"}}>
            <div
                className="card"
                style={{ 
                    backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/dealerportal-2fb18.appspot.com/o/profile-editor-bg.png?alt=media')",
                    height: "100%" 
                }}
            >
                <div className="overlay"></div>
                <div className="top-info">
                    <h2>Rep Tools</h2>
                    <div className="bottom-info">
                        Everything you need to support our resellers
                    </div>

                    <Grid container className="download-box" spacing={2} style={{paddingTop: 16}}>
                        <Grid item xs={12}>
                            <NewResellerInvite title="Invite a New Reseller" />
                        </Grid>

                        <Grid item xs={12}>
                            <NewDemoButton />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                className="btn"
                                component={Link}
                                to={"/company?tab=documents#tools"}
                            >
                                Sales Tools
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                className="btn"
                                component={Link}
                                to={"/demo-form?type=demo-feedback"}
                            >
                                Create Demo Feedback
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                className="btn"
                                component={Link}
                                to={"/demo-form?type=phone-call"}
                            >
                                Log Reseller Call
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                className="btn"
                                component={Link}
                                to={"/demo-form?type=visit"}
                            >
                                Log Reseller Visit
                            </Button>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </Grid>
    );
};
 
export default RepToolbox;