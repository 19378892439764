import React from "react";
import { NavigateFunction, useNavigate, useLocation,  } from "react-router-dom";
import ReactGA from "react-ga4";

interface ViewContextInterface {
  currentPanel: SideDrawerTab,
  saveCurrentPanel: (panel: any) => void
}

export const ViewContext = React.createContext<ViewContextInterface>(null);

export enum SideDrawerTab {
  home = "home",
  products = "products",
  orders = "orders",
  company = "company",
  admin = "admin",
  documents = "documents",
  help = "help",
  demos = "demo",
  pd = "pd",
  reports = "reports"
}

const adminRoutes = [
    "admin",
    "edit-company",
    "edit-user",
    "status"
];

const orderRoutes = [
    "order-history",
    "pending-orders"
];

const productRoutes = [
    "checkout",
    "products"
];

const demoRoutes = [
    "demo-form",
    "demos",
    "my-demos",
    "my-demo-reviews"
];

const pdRoutes = [
    "pd",
    "vendors",
];

interface ViewProviderProps {
    children: any,
}

export const ViewProvider = ({ children }: ViewProviderProps) => {
    const [currentPanel, setCurrentPanel] = React.useState<SideDrawerTab>();
    const navigate = useNavigate(); 
    const location = useLocation(); 
    const { hash, pathname, search } = location;

    // React.useEffect(() => {
    //    history.listen((location) => {
    //     checkLocation(location);
    //    });

    // },[history]) 

    const checkLocation = () => {
        let tab = SideDrawerTab.home;
        const path = pathname.toLowerCase();
        // console.log("sending " + path);
      
        ReactGA.send("pageview");
        // ReactGA.send({ hitType: "event", eventCategory: "auth", eventAction: "login", eventLabel: email.value });
        if(new RegExp(adminRoutes.join("|")).test(path)) {
            tab = SideDrawerTab.admin;
        } else if(path.includes("help")) {
            tab = SideDrawerTab.help;
        } else if (search.includes("documents")) {
            tab = SideDrawerTab.documents;        
        } else if(path.includes("company")) {
            tab = SideDrawerTab.company;
        } else if(new RegExp(orderRoutes.join("|")).test(path)) {
            tab = SideDrawerTab.orders;
        } else if(new RegExp(productRoutes.join("|")).test(path)) {
            tab = SideDrawerTab.products;
        } else if(new RegExp(demoRoutes.join("|")).test(path)) {
            tab = SideDrawerTab.demos;
        } else if(path.includes("reports")) {
            tab = SideDrawerTab.reports;
        } else if(new RegExp(pdRoutes.join("|")).test(path)) {
            tab = SideDrawerTab.pd;
        }

        if(tab != currentPanel) setCurrentPanel(tab);
    };

    // const updateCurrentPanelByString = (panelName) => {
    //   let tab = Object.values(SideDrawerTab).find(t => t == panelName);
    //   if(tab) setCurrentPanel(tab);
    //   else {
    //     setCurrentPanel(SideDrawerTab.home);
    //   }
    // }

    const saveCurrentPanel = (panel: SideDrawerTab) => {
    // localStorage.setItem("currentPanel", panel);
        setCurrentPanel(panel);
        window.scrollTo(0, 0);
    };

    React.useEffect(() => {
    // const localPanel = localStorage.getItem("currentPanel");
    // if (localPanel) {
    //   updateCurrentPanelByString(localPanel);
    // }
        checkLocation();
    }, []);

    return (
        <ViewContext.Provider
            value={{
                currentPanel,
                saveCurrentPanel,
            }}
        >
            {children}
        </ViewContext.Provider>
    );
};
