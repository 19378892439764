import React from "react";
import { ProductCategories, productFromHasuraObject, ProductInterface, ProductWebflowCollectionItemInterface, ProductWebflowFieldInterface, WebflowCollections } from "interfaces/Product";
import { Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from "@material-ui/core";
import { ProductContext } from "context/Product";
import { makeAxiosCall } from "utils";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import DraftEditor from "components/utils/DraftEditor";
import { ClearAll, DeleteForever, Info } from "@material-ui/icons";
import { getWarrantyTypeIdFromName, ProductWarrantyTypes } from "interfaces/Warranty";
import { sortWebflowFields } from "interfaces/ProductEditor";
import { DatePicker } from "@material-ui/pickers";
import { Moment } from "moment";

interface WebflowEditorProps {
  product: ProductInterface,
  setProduct: Function
}

const WebflowEditor = ({ product, setProduct }: WebflowEditorProps) => {
  const { products } = React.useContext(ProductContext);
  const [snackbar, setSnackbar] = React.useState({
      isOpen: false,
      message: "",
      severity: "success",
  });
  const [productCollections, setProductCollections] = React.useState<string[]>([]);
  const [potentialProductTags, setPotentialProductTags] = React.useState<string[]>([]);
  const [warranty, setWarranty] = React.useState<string>(product.warranty);
  const [showStatusInfo, setShowStatusInfo] = React.useState<boolean>(false);
  const [showLaunchDateInfo, setShowLaunchDateInfo] = React.useState<boolean>(false);
  const tagFilter = createFilterOptions<string>();

  React.useEffect(() => {
    const handleSaveShortcutWithState = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'S') {
        saveDetails();
      }
    };

    window.addEventListener('keydown', handleSaveShortcutWithState);

    return () => {
      window.removeEventListener('keydown', handleSaveShortcutWithState);
    };
  }, [productCollections]); // Re-bind when productCollections changes


  React.useEffect(() => {
    let newProductCollections = [];
    for (let pi = 0; pi < product.collections.length; pi++) {
      const c = product.collections[pi];
      newProductCollections.push(c.collection.id)
    }

    setProductCollections(newProductCollections);
    loadTags();
  }, []);

  const saveDetails = () => {
    if(!product.websiteUrl || product.websiteUrl.length < 1) {
      setSnackbar({
        isOpen: true,
        message: "Please add a unique url slug for this product",
        severity: "error"
      });

      return;
    }
    setSnackbar({
        isOpen: true,
        message: "Saving...",
        severity: "info"
    });

    let newCollections = [];

    for (let ci = 0; ci < productCollections.length; ci++) {
      const collectionId = productCollections[ci];
      let collection = WebflowCollections.find((c) => c.id == collectionId);
      let existingCollection = product.collections.find((c) => c.collection.id == collectionId);
      if(existingCollection) {
        newCollections.push(existingCollection);
      } else {
        newCollections.push({
          itemId: "",
          collection: collection
        });
      }
    }

    let updatedProduct = {...product, collections: newCollections, webflowFields: []};

    // Make sure we're sending full product data
    for (let wfi = 0; wfi < product.webflowFields.length; wfi++) {
      const singleField = product.webflowFields[wfi];

      if(singleField.fieldType == "Product Selector") {
        let fullRelatedProductObjects = [];
        if(singleField.value && singleField.value.length > 0) {
          for (let rpi = 0; rpi < singleField.value.length; rpi++) {
            const relatedProduct = singleField.value[rpi];

            if(typeof relatedProduct == "string") {
              let matchingProduct = products.find((p) => p.id == parseInt(relatedProduct));
              if(matchingProduct) {
                fullRelatedProductObjects.push(matchingProduct);
              }
            } else if(typeof relatedProduct == "number") {
              let matchingProduct = products.find((p) => p.id == relatedProduct);
              if(matchingProduct) {
                fullRelatedProductObjects.push(matchingProduct);
              }
            } else if(typeof relatedProduct == "object") {
              fullRelatedProductObjects.push(relatedProduct);
            }
          }
        }
        updatedProduct.webflowFields.push({...singleField, value: fullRelatedProductObjects});
      } else {
        if(singleField.slug == "tags") { // clean up the tags
          let splitTags: string[];
          if(!singleField.value) splitTags = [];
          else splitTags = singleField.value.split(',');
          
          let cleanTagArray = [];
          
          for (let ti = 0; ti < splitTags.length; ti++) {
            const cleanTag = splitTags[ti].trim();
            if(cleanTag.length > 1) {
              cleanTagArray.push(cleanTag);
            }
          }
          singleField.value = cleanTagArray.join(',');
        }

        updatedProduct.webflowFields.push(singleField);
      }
    }

    if(warranty) {
      updatedProduct.warrantyTypeId = getWarrantyTypeIdFromName(warranty);
      updatedProduct.warranty = warranty;
    }

    makeAxiosCall("POST", `product/${product.id}`, updatedProduct).then((response) => {
        if(response.error) {
            setSnackbar({
              isOpen: true,
              message: "Save Failed: " + response.error,
              severity: "error"
            });
        } else {
          setProduct(productFromHasuraObject(response.data));

          setSnackbar({
            isOpen: true,
            message: "Save Successful",
            severity: "success"
          });
        }
    }).catch((response) => {
      if(response.data && response.data.error) {
        console.log(response);
        setSnackbar({
          isOpen: true,
          message: "Save Failed: " + response.data.error,
          severity: "error"
        });
      }
      else {
        setSnackbar({
          isOpen: true,
          message: "Save Failed. Check the logs, or talk to Devon.",
          severity: "error"
        });
      }
    })
  }

  const loadTags = () => {
      makeAxiosCall("GET", `product-tags`).then((response) => {
        if(response.error) {
            console.log(response);
            setSnackbar({
              isOpen: true,
              message: "Failed to load tags: " + response.error,
              severity: "error"
            });
        } else {

          let newTags = potentialProductTags;
          for (let ti = 0; ti < response.data.length; ti++) {
            const tag = response.data[ti];
            if(potentialProductTags.indexOf(tag) == -1) {
              newTags.push(tag);
            }
          }

          setPotentialProductTags(newTags)
        }
    });
  }

  const saveTag = (allCurrentTags: string[]) => {
    for (let ti = 0; ti < allCurrentTags.length; ti++) {
      const currentTag = allCurrentTags[ti].trim();

      if(!potentialProductTags.some((pt: string) => currentTag.toLowerCase() == pt.trim().toLowerCase())) {
          makeAxiosCall("POST", `product-tag`, {tag: currentTag}).then((response) => {
            if(response.error) {
                console.log(response);
                setSnackbar({
                  isOpen: true,
                  message: "Failed to create new tag("+ currentTag +"): " + response.error,
                  severity: "error"
                });
            } else {
              setPotentialProductTags([...potentialProductTags, currentTag])
              setSnackbar({
                isOpen: true,
                message: "Saved tag for later ("+ currentTag +")",
                severity: "success"
              });
            }
        });
      }
    }

  }

  const updateField = (fieldId: number, newValue: any) => {
    if(newValue == "<p></p>" || newValue == "<p></p>\\n" || newValue == "<p></p>\n") newValue = "";
    let newProduct = {...product, webflowFields: []};
    for (let wfi = 0; wfi < product.webflowFields.length; wfi++) {
      const singleField = product.webflowFields[wfi];
      if(singleField.customFieldId != fieldId) {
        newProduct.webflowFields.push(singleField); // not a match
      } else {
        if(singleField.fieldType == "Product Selector") {
          if(newValue.length > 0) {
            let fullRelatedProductObjects = [];
            for (let rpi = 0; rpi < newValue.length; rpi++) {
              let relatedProduct = newValue[rpi];

              if(typeof relatedProduct == "string") {
                relatedProduct = products.find((p) => p.id == parseInt(relatedProduct));
                if(relatedProduct) {
                  fullRelatedProductObjects.push(relatedProduct);
                }
              } else if(typeof relatedProduct == "number") {
                relatedProduct = products.find((p) => p.id == relatedProduct);
                if(relatedProduct) {
                  fullRelatedProductObjects.push(relatedProduct);
                }
              } else if(typeof relatedProduct == "object") {
                fullRelatedProductObjects.push(relatedProduct);
              }

              if(relatedProduct) {
                for (let ci = 0; ci < newProduct.collections.length; ci++) {
                  const currentProductCollection = newProduct.collections[ci];
                  if(!relatedProduct.collections.find((rpc: ProductWebflowCollectionItemInterface) => rpc.collection.id == currentProductCollection.collection.id)) {
                    setSnackbar({
                      isOpen: true,
                      message: "Heads up! " + relatedProduct.name + " is not in all of the same collections as " + product.name + ". It will still save, but will not be related on the " + currentProductCollection.collection.name + " site.",
                      severity: "warning"
                    });
                  }

                }
              }
            }
            newValue = fullRelatedProductObjects;
          }
        }
        newProduct.webflowFields.push({...singleField, value: newValue});
      }
    }

    setProduct(newProduct);
  }

  const statusInfoDialog = () => {
    return (
        <Dialog
            open={showStatusInfo}
            onClose={toggleStatusInfo}
        >
            <DialogTitle>
              It's all about status
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                  The <strong>On Portal</strong> checkbox determines if reps and resellers can see the product on the portal. <br /><br />
                  The <strong>Public</strong> checkbox determines if the product is a <strong>draft</strong> on WebFlow. (checked = visible = not draft)<br /><br />
                  The <strong>Discontinued</strong> checkbox determines if the product is <strong>discontinued</strong>. This is more or less just styling changes at this point.<br /><br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleStatusInfo} autoFocus>
                  Gotcha
                </Button>
            </DialogActions>
        </Dialog>
    );
  };

  const launchDateInfoDialog = () => {
    return (
        <Dialog
            open={showLaunchDateInfo}
            onClose={toggleLaunchDateInfo}
        >
            <DialogTitle>
              Official Launch Date of Product
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                  <strong>THIS IS ONLY FOR SHOWING THE "NEW" FLAG</strong><br /><br />
                  If this date is more recent than <strong>6 months</strong> a "NEW" flag will be shown by the product.<br /><br />
                  It does not indicate anything else. Nothing in the pricing code, reports, or automation.<br /><br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleLaunchDateInfo} autoFocus>
                  Gotcha
                </Button>
            </DialogActions>
        </Dialog>
    );
  };

  const buildWarrantyComponent = () => {
    return (
      <Grid item xs={12} key={"warranty-prod-select"}>
        <Autocomplete
            value={warranty}
            fullWidth={true}
            disableClearable={true}
            options={ProductWarrantyTypes}
            style={{paddingBottom: 16}}
            onChange={(event, newValue) => {
              setWarranty(newValue);
            }}
            getOptionLabel={(warranty) => warranty ? warranty : "Warranty Not Found"}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Warranty"}
                fullWidth
              />
            )}
        />
      </Grid>
    );
  }

  const buildProductSelectorComponent = (fieldData: ProductWebflowFieldInterface) => {

    let fieldValue = fieldData.value;

    if(!fieldValue) fieldValue = [];

    let relatableProducts = [];
    if(fieldData) {
      if(!fieldData.validateCollectionIds) {
        console.log("no validation?");
      } else {
        let prods = products.filter((p) => p.collections.find((c) => fieldData.validateCollectionIds.includes(c.collection.id)));
        relatableProducts = prods;
      }
    }

    if(fieldValue.length > 0 && (typeof fieldValue[0] != "object")) {
      // gotta fix the field
      let productIds = [...fieldData.value];
      let newFieldValue = [];
      for (let rpi = 0; rpi < productIds.length; rpi++) {
        const productId = productIds[rpi];

        const rp = products.find((p) => p.id == productId);
        if(rp) newFieldValue.push(rp);
        else console.log("couldn't find product: " + productId);
      }
      fieldValue = newFieldValue;
    }



    return (
      <Grid item xs={12} key={"webflow-field-key-prod-sel-" + fieldData.slug}>
        <Autocomplete
            value={fieldValue}
            multiple={true}
            fullWidth={true}
            options={relatableProducts ? relatableProducts : []}
            onChange={(event, newValue) => {
              updateField(fieldData.customFieldId, newValue);
            }}
            getOptionLabel={(product) => product ? product.name : "Product Not Found"}
            renderInput={(params) => (
              <TextField
                {...params}
                label={fieldData.name}
                variant="outlined"
                fullWidth
              />
            )}
        />
      </Grid>
    );
  }

  const toggleStatusInfo = () => {
    setShowStatusInfo(!showStatusInfo);
  }

  const toggleLaunchDateInfo = () => {
    setShowLaunchDateInfo(!showLaunchDateInfo);
  }

  const buildLaunchDateField = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <InputLabel id="status-toggles-label">
            Launch Date
            <Info onClick={toggleLaunchDateInfo} />
          </InputLabel>
        </Grid>
        <Grid item xs={10} md={11}>
          <DatePicker 
              value={product.productLaunchDate}
              onFocus={() => {
                  if(!product.productLaunchDate || product.productLaunchDate.getUTCFullYear() < 1992) {
                    setProduct({...product, productLaunchDate: null});
                  }
                }
              }
              onChange={function (date: Moment): void {
                let newLaunchDate = date.toDate();
                newLaunchDate.setHours(0,0,0,0);
                setProduct({...product, productLaunchDate: newLaunchDate});
              }}
              initialFocusedDate={new Date()}
              format={"MM/DD/yy"}
              style={{width: "100%"}}
            />
        </Grid>
        <Grid item xs={2} md={1}>
          <DeleteForever style={{width: "100%", height: 35}} onClick={() => setProduct({...product, productLaunchDate: null})}/>
        </Grid>
      </Grid>
    );
  }

  const buildStatusToggles = () => {
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <InputLabel id="status-toggles-label">
            Status
            <Info onClick={toggleStatusInfo} />
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
              control={
                <Checkbox
                    checked={product.status.visiblePortal}
                    onChange={(e) => {
                      setProduct({...product, status: {...product.status, visiblePortal: !product.status.visiblePortal}})
                    }}
                />
              }
              label={"On Portal"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
              control={
                <Checkbox
                    checked={product.status.visiblePublic}
                    onChange={(e) => {
                      setProduct({...product, status: {...product.status, visiblePublic: !product.status.visiblePublic}})
                    }}
                />
              }
              label={"Public"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
              control={
                <Checkbox
                    checked={product.status.discontinued}
                    onChange={(e) => {
                      setProduct({...product, status: {...product.status, discontinued: !product.status.discontinued}})
                    }}
                />
              }
              label={"Discontinued"}
          />
        </Grid>
      </Grid>
    );
  }

  const buildCollectionSelect = () => {
    return (
      <FormControl fullWidth={true}>
        <InputLabel id="collection-select-label">Collections</InputLabel>
        <Select
            multiple={true}
            value={productCollections}
            fullWidth={true}
            onChange={(e: React.ChangeEvent<{ name?: string, value: string[] }>) => {
              setProductCollections(e.target.value);
            }}
            renderValue={(selected?: string[]) => {
              if(!selected || selected.length == 0) return "None";
              let rvs = [];
              for (let spci = 0; spci < selected.length; spci++) {
                const collectionId = selected[spci];
                rvs.push(WebflowCollections.find((c) => c.id == collectionId).name);
              }
              return rvs.join(', ');
            }}
            labelId={"status-select-label"}
        >
            {WebflowCollections.map(
                (collection) => {
                    const active: boolean = !!productCollections.find((c) => c == collection.id);
                    return (
                        <MenuItem
                            key={"status-key-" + collection.id}
                            value={collection.id}
                        >
                            <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                            {collection.name}
                        </MenuItem>
                    );
                }
            )}
        </Select>
      </FormControl>
    );
  }

  const buildTagSelectComponent = (fieldData: ProductWebflowFieldInterface) => {
    let currentValues: string[];
    if(!fieldData.value) currentValues = [];
    else currentValues = fieldData.value.split(',');
    let newTag = potentialProductTags.length > currentValues.length && currentValues.some((t: string) => !potentialProductTags.some((pt: string) => t.trim().toLowerCase() == pt.trim().toLowerCase()));
    return (
      <Grid item container xs={12} key={"webflow-field-key-tag-" + fieldData.slug}>
        <Grid item xs={9} key={"tag-field"}>
          <Autocomplete
              value={currentValues}
              multiple={true}
              fullWidth={true}
              options={potentialProductTags ? potentialProductTags : []}
              freeSolo={true}
              filterOptions={(options, params) => {
                const normalFiltered = tagFilter(options, params);

                let filtered = [];
                
                for (let fi = 0; fi < normalFiltered.length; fi++) {
                  const tag = normalFiltered[fi];

                  if(currentValues.some((t: string) => t.trim().toLowerCase() == tag.trim().toLowerCase()) || tag.trim().toLowerCase() == params.inputValue.trim().toLowerCase()) {
                    continue;
                  } else {
                    filtered.push(tag);
                  }
                }

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              onChange={(event, newValue) => {
                let newBuiltValue = newValue.join(', ');

                updateField(fieldData.customFieldId, newBuiltValue);
              }}
              getOptionLabel={(tag) => tag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={fieldData.name}
                  variant="outlined"
                  fullWidth
                  helperText={"'Save New Tag' saves it to the list of tags for later. To save the product's tag list, just save the product normally."}
                />
              )}
          />
        </Grid> 
        <Grid item xs={3} key={"tag-field-add-button"} style={{padding: 8}}>
          <Button
              onClick={() => {
                  saveTag(currentValues);
              }}
              disabled={!newTag}
              variant="contained"
              fullWidth={true}
              color="primary"
              className="btn"
          >
              Save New Tag
          </Button>
        </Grid>
      </Grid>
    );
  }

  const buildProductCategoryComponent = (fieldData: ProductWebflowFieldInterface) => {
    return (
      <Grid item xs={12} md={6} key={"webflow-field-key-prod-cat-" + fieldData.slug} >
        <FormControl fullWidth={true}>
          <InputLabel id="category-select-label">Product Category</InputLabel>
          <Select
              value={fieldData.value}
              fullWidth={true}
              onChange={(e) => {
                updateField(fieldData.customFieldId, e.target.value)
              }}
              labelId={"category-select-label"}
          >
              {ProductCategories.map(
                  (category) => {
                      const active: boolean = fieldData.value == category;
                      return (
                          <MenuItem
                              key={"status-key-" + category}
                              value={category}
                          >
                              <i className={active ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: active ? "green" : "grey" }}></i>
                              {category}
                          </MenuItem>
                      );
                  }
              )}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  const buildTextFieldComponent = (fieldData: ProductWebflowFieldInterface) => {
    return (
        <Grid item xs={12} md={6} key={"webflow-field-key-text-" + fieldData.slug}>
            <TextField
              onChange={(e) => {
                updateField(fieldData.customFieldId, e.target.value)
              }}
              value={fieldData.value ?? ""}
              style={{ width: "100%" }}
              label={fieldData.name}
              margin="normal"
              variant="outlined"
            />
        </Grid>
    );
  }

  const buildCheckboxComponent = (fieldData: ProductWebflowFieldInterface) => {
    return (
        <Grid item xs={12} md={6} key={"webflow-field-key-check-" + fieldData.slug} >

            <FormControlLabel
              control={
                <Checkbox
                    checked={!!fieldData.value}
                    onChange={(e) => {
                      updateField(fieldData.customFieldId, !fieldData.value)
                    }}
                />
              }
              label={fieldData.name}
              classes={{ label: "search-filter-option" }}
          />
        </Grid>
    );
  }

  const buildHTMLComponent = (fieldData: ProductWebflowFieldInterface) => {
    return (
        <Grid item xs={12} md={12} key={"webflow-field-key-html-" + fieldData.slug}>
            <p>{fieldData.name}:</p>
            <DraftEditor
              onChange={(newHTML: string) => {
                updateField(fieldData.customFieldId, newHTML);
              }}
              startingHTML={fieldData.value ?? ""}
              style={{ width: "100%" }}
              fieldName={fieldData.name}
            />
        </Grid>
    );
  }

  const buildDetails = () => {
    if(!product.webflowFields || !product.webflowFields.length) return <h3>No webflow fields (Probably not in a collection)</h3>;

    product.webflowFields = sortWebflowFields(product.webflowFields);

    let detailComponents = [];

    for (let di = 0; di < product.webflowFields.length; di++) {
        const fieldData = product.webflowFields[di];
        if(fieldData.name.toLowerCase().indexOf("image") > -1) continue; // skip image fields

        if(fieldData.slug == "tags") {
          detailComponents.push(buildTagSelectComponent(fieldData));
        } else if(fieldData.slug == "product-type") {
          detailComponents.push(buildProductCategoryComponent(fieldData));
        } else {

          switch(fieldData.fieldType) {
            case "Checkbox":
              detailComponents.push(buildCheckboxComponent(fieldData));
              break;
            case "Product Selector":
              detailComponents.push(buildProductSelectorComponent(fieldData));
              break;
            case "Long Text":
              detailComponents.push(buildHTMLComponent(fieldData));
              break;
            case "Text":
              detailComponents.push(buildTextFieldComponent(fieldData));
              break;
            default:
              console.log("WHAT FIELD TYPE IS THIS?");
              console.log(fieldData);
              break;
          }
        }

    }

    return detailComponents;
  }

  const buildBasicInfo = () => {
    return (
      <Grid item xs={12}>
        <Card style={{padding: 24, border: "2px solid #0047ba"}} elevation={0} >
          <Grid container spacing={3}>

            <TextField
                onChange={(e) => {
                  setProduct({...product, name: e.target.value})
                }}
                value={product.name ?? ""}
                style={{ width: "100%" }}
                id="name-field"
                label={"Name"}
                margin="normal"
                variant="outlined"
                helperText={"This name is used in the portal too"}
                fullWidth={true}
              />
            <Grid item xs={12} md={6}>
              <h4>Product Type: {product.productType}</h4>
              {buildWarrantyComponent()}
              {buildCollectionSelect()}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* {buildStatusDropdown()} */}
              {buildStatusToggles()}
              <TextField
                onChange={(e) => {
                  setProduct({...product, websiteUrl: e.target.value})
                }}
                value={product.websiteUrl ?? ""}
                style={{ width: "100%" }}
                id="slug-field"
                label={"Slug"}
                margin="normal"
                variant="outlined"
                fullWidth={true}
              />
              {buildLaunchDateField()}
            </Grid>

          </Grid>
        </Card>
      </Grid>
    );
  }

  return (
    <div style={{ marginBottom: 20 }}>
      {statusInfoDialog()}
      {launchDateInfoDialog()}
        <Grid container spacing={3}>
            {buildBasicInfo()}
            {buildDetails()}
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        saveDetails();
                    }}
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    className="btn"
                >
                    Save
                </Button>
            </Grid>
        </Grid>

        <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={snackbar.severity == "error" || snackbar.severity == "warning" ? 30000 : 3000}
                onClose={(_, reason) => {
                    setSnackbar({ ...snackbar, isOpen: false });
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
                >
                    {snackbar.message}
                </Alert>
        </Snackbar>
    </div>
  );
}

export default WebflowEditor;