import { productFromHasuraObject, ProductInterface, ProductDimensionsInterface, buildProductDimensions, ProductMediaInterface, buildProductMedia } from "./Product";
import { BasicUserInterface } from "./User";



export interface PDProjectInterface {
    id: number,
    name: string,
    description: string,
    price: number,
    mainImage: string,
    product?: ProductInterface,
    productDimensions?: ProductDimensionsInterface,
    lastUpdate: Date,
    lead: BasicUserInterface,
    updates: PDProjectUpdateInterface[],
    status: PDProjectStatusInterface,
    statusDetails: string,
    statusValue: number,
    baubles: PDProductBaubleInterface[],
    factory: string,
    media: PDProjectMediaInterface[],
    isWaiting: boolean,
    checklists: PDProjectChecklistInterface[],
    projectType: PDProjectTypeInterface
}


export const emptyPDProjectInterface = () : PDProjectInterface => {
    return {
        id: 0,
        name: "",
        description: "",
        price: 0,
        mainImage: "",
        lastUpdate: new Date(),
        lead: {
            id: "nobody",
            name: "Clark",
            email: "Kent"
        },
        updates: [],
        status: {
            id: 1,
            name: "At Bat"
        },
        statusDetails: "",
        statusValue: 0,
        baubles: [],
        factory: "",
        media: [],
        isWaiting: false,
        checklists: [],
        projectType: {
            id: 1,
            name: "New Product"
        }
    }
}


export const BuildSinglePDProject = (data): PDProjectInterface => {

    let updateList: PDProjectUpdateInterface[] = [];

    if(data.updates) {
        for (let ui = 0; ui < data.updates.length; ui++) {
            const pud = data.updates[ui];
            updateList.push(BuildPDUpdate(pud));
        }
    }

    updateList.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf());
    
    let samplesList: PDProductBaubleInterface[] = [];

    if(data.baubles) {
        for (let ui = 0; ui < data.baubles.length; ui++) {
            const samd = data.baubles[ui];
            samplesList.push(BuildPDBauble(samd));
        }
        samplesList.sort((a, b) => +a.createdAt - +b.createdAt)
    }
    
    let mediaList: PDProjectMediaInterface[] = [];

    if(data.media) {
        for (let ui = 0; ui < data.media.length; ui++) {
            const med = data.media[ui];
            mediaList.push(buildProjectMedia(med));
        }
    }

    let checklistList: PDProjectChecklistInterface[] = [];

    if (data.checklists && data.checklists.length) {
      for (let ci = 0; ci < data.checklists.length; ci++) {
        const checklistData = data.checklists[ci];
        checklistList.push(buildProjectChecklist(checklistData));
      }
    } else {
    //   checklistList.push(DefaultPDProgressChecklist);
    }

    let statusValue = 0;

    switch(data.project_status.id) {
        case 1: 
            statusValue = 0;
            break;
        case 2: 
            statusValue = 1;
            break;
        case 3: 
            statusValue = 2;
            break;
        case 4: 
            statusValue = 3;
            break;
        case 5: 
            statusValue = 4;
            break;
        default: 
            statusValue = -1;
            break;
    }

    return {
        id: data.id,
        name: data.name,
        description: data.description,
        price: data.unit_price,
        mainImage: data.main_image_url,
        product: data.product ? productFromHasuraObject(data.product) : null,
        productDimensions: data.dimensions ? buildProductDimensions(data.dimensions) : null,
        lastUpdate: new Date(data.updated_at),
        lead: {
            id: data.project_owner.id,
            name: data.project_owner.first_name,
            email: data.project_owner.email
        },
        updates: updateList,
        status: data.project_status,
        statusDetails: data.status_details,
        statusValue: statusValue,
        baubles: samplesList,
        factory: data.factory,
        media: mediaList,
        isWaiting: data.is_waiting,
        checklists: checklistList,
        projectType: getPDProjectType(data.project_type_id)
    };
}


export const BuildPDProjectList = (data): PDProjectInterface[] => {
    let projects: PDProjectInterface[] = [];

    if(data && data.length) {
        for (let pi = 0; pi < data.length; pi++) {
            const projRaw = data[pi];
            projects.push(BuildSinglePDProject(projRaw))
        }
    }

    return projects;
};

export interface PDProductBaubleInterface {
    id: number,
    type: string,
    status: string,
    createdAt: Date,
    createdBy: string,
    name: string,
    vendorPartName?: string,
    description: string,
    mainSample: boolean,
    changelog?: string,
}


export const BuildPDBauble = (data): PDProductBaubleInterface => {
    return {
        id: data.id,
        type: data.type,
        status: data.status,
        createdAt: new Date(data.created_at),
        createdBy: data.created_by ? data.created_by.first_name : "Anon",
        name: data.name,
        vendorPartName: data.vendor_part_name,
        description: data.description ?? "",
        mainSample: data.is_main_sample,
        changelog: data.changelog,
    };
}

export const emptyPDProjectSample = () : PDProductBaubleInterface => {
    return {
        id: 0,
        type: "Sample",
        status: "Ordered",
        createdAt: new Date(),
        createdBy: "",
        name: "",
        description: "",
        mainSample: false
    }
}

export interface PDProjectUpdateNotificationInterface {
    id: number,
    notifiedAt: Date,
    createdBy: string,
    sentTo: string
}

export interface PDProjectUpdateInterface {
    id: number,
    headline: string,
    details: string,
    createdBy: string,
    createdAt: Date,
    notifications: PDProjectUpdateNotificationInterface[]
}

export const emptyPDProjectUpdate = () : PDProjectUpdateInterface => {
    return {
        id: 0,
        headline: "",
        details: "",
        createdBy: "",
        createdAt: new Date(),
        notifications: []
    }
}

export const BuildPDUpdate = (data): PDProjectUpdateInterface => {

    let notificationsList: PDProjectUpdateNotificationInterface[] = [];

    // NOT IMPLIMENTED YET
    if(data.notificationsList) {
        for (let ni = 0; ni < data.notificationsList.length; ni++) {
            const nd = data.notifications[ni];
            notificationsList.push({
                id: nd.id,
                notifiedAt: new Date(nd.notified_at),
                createdBy: nd.created_by ? nd.created_by.first_name : "Anon",
                sentTo: nd.sent_to
            });
        }
    }

    return {
        id: data.id,
        headline: data.headline,
        details: data.details,
        createdBy: data.created_by ? data.created_by.first_name : "Anon",
        createdAt: new Date(data.created_at),
        notifications: notificationsList
    };
}

export const PDProjectBaubleTypes: string[] = [
    "Sample",
    "Firmware Uploader",
    "Part",
    "Accessory"
];

export const PDProjectBaubleStatuses: string[] = [
    "Being Prepared",
    "Ordered",
    "Here",
    "Done/Gone"
];

export interface PDProjectStatusInterface {
    id: number,
    name: string
}

export const PDProgressProjectStatuses: PDProjectStatusInterface[] = [
    {id: 1, name: "At Bat (Initial Contact/Sample/Look)"},
    {id: 2, name: "First Base (Creating Change List)"},
    {id: 3, name: "Second Base (Making Changes)"},
    {id: 4, name: "Third Base (Packaging & Design)"},
    {id: 5, name: "Home Stretch (Finalization)"},
    {id: 6, name: "Dugout (Backburner or Done)"}
];

// Tech Review: categories
export const PDReviewStatuses: PDProjectStatusInterface[] = [
    {id: 7, name: "Operations"},
    {id: 8, name: "General"},
    {id: 9, name: "DMX Profiles"},
    {id: 10, name: "Fixture Sound Levels"},
    {id: 11, name: "Manual Accuracy"},
    {id: 12, name: "Safety Assessment"},
    {id: 13, name: "Hardware"},
    {id: 14, name: "Torture Test"},
    {id: 15, name: "Other"},
];

export const PDProjectStatuses: PDProjectStatusInterface[] = [
    ...PDProgressProjectStatuses,
    ...PDReviewStatuses,
];

export const HardCodedFactoryList: string[] = [
    "Daisy Group Co., Ltd.",
    "NovaStar North America, Inc.",
    "Vello Light Co., Ltd.",
    "Ningbo Central Star Electronic Technology",
    "NovaStar Tech",
    "WANSHI SOUND & LIGHTING CO.,LIMITED",
    "Guangzhou YiLong Stage Lighting Factory",
    "Birun Electronic Industrial",
    "Guangzhou Spark Stage Equipment",
    "TOPLED Lighting Electronics Limited",
    "Gleelite CO Limited",
    "Senseled Technology Co., Limited",
    "Guangzhou Ever Famous (\"Goldbright\")",
    "Shenzhen Lanling Technology Co., Ltd.",
    "Color Imagination LED Lighting Limited",
    "Daan Lighting",
    "RainStar",
    "LuxMore Professional, Ltd.",
    "Guangzhou BKLite Stage Lighting Equipment",
    "Yellow River Lighting (Chinese Yuan)",
    "Colorful Light (HK) Limited",
    "LumiDesk / Nicolaudie America",
    "VanGaa Lighting CO",
    "AMT Lighting",
    "LED Spirit Co., Ltd. (HK)",
    "Guangzhou Anmingli Stage Light",
    "Shenzhen Dicolor Optoelectronics Co., Ltd",
    "GZ Leahua Light Co,.Limited",
    "Guangzhou New Caiyi Stage Light Factory",
    "FuFeng Lighting",
    "MEGA Systems, Inc",
    "NovaStar Warehouse (Vendor)",
    "Guangzhou Beyond Lighting Co.",
    "Nebula",
    "Absen, Inc.",
    "ACMETEC HOLDINGS LIMITED",
    "Guangzhou ILightings Equipment (Yuan)",
    "Seetronic Ningbo Co., Ltd",
    "Master FX",
    "Work Pro Lighting Solutions (Euro)",
    "CODE Electronic CO., LTD.",
    "Guangzhou Xinyu Stage Lighting",
    "Cheaplights",
    "MCM Electronics",
    "NIGHTSUN LIGHTING & AUDIO EQUIPMENT CO.,L",
    "Newark Electronics",
    "Nuoma/Junma Lighting",
    "Guangzhou Xiang Ming Light Limited",
    "Guangzhou GTD Lighting Technology Co, LTD",
    "Pixelhue Technology Co., Ltd",
    "Guangzhou ILightings Equipment Co.,Ltd.",
    "Intellasystem",
    "1800Flowers",
    "Digi-Key Electronics",
    "ArKaos S.A.",
    "Aton Light CO., LTD",
    "New Caiyi",
    "SINFEN GROUP UK LTD",
    "Amazon",
    "Polytech Design Inc.",
    "imrbatteries.com",
    "Jobet Light Co., Limited",
    "Dongguan Sibositong Industrial Co., Ltd.",
    "OSRAM",
    "Pomelo Photoelectric Equipment LTD",
    "Yoline Lighting Equipment Co., Ltd.",
    "Huayin Lighting Co., Limited",
    "ShenZhen Sunricher Technology LTD",
    "Light Sky",
    "Guangzhou Meitian Stage Lighting",
    "Guangzhou Theia Optoelectronics Tech",
    "Longman International Group Limited",
    "LED Engin, Inc.",
    "Guangzhuo Chuanghui Lighting Co.",
    "VCELL  POWER CO., LTD",
    "AliExpress",
    "PCBWay",
    "Real Tech International Ltd.",
    "MI Distributing",
    "AAA-LIGHT&AUDIO HK CO.,LTD",
    "ooShirts",
    "Parts Express",
    "Shenzhen Artec Visual Co., Ltd",
    "Other"
]

export const HardCodedPDPeople: BasicUserInterface[] = [
    {
        id: "wJNJBrXUIPPWKeHkAzSOXU9ox6v2",
        name: "Devon DeVoss",
        email: "devon@blizzardpro.com",
        companyId: 512
    },
    {
        id: "I3IkT6RyYvXva04Xurne95Qk6xB2",
        name: "Will Komassa",
        email: "will@blizzardpro.com",
        companyId: 512
    },
    {
        id: "5tvYB1QuSxPFaDFM5EnEahBC6Qw2",
        name: "Joe Thomas",
        email: "joe@blizzardpro.com",
        companyId: 512
    },
    {
        id: "VQn91NnOu5WaRoZqNyTjPHm6i7G3",
        name: "Ryan Chua",
        email: "ryanchua@blizzardpro.com",
        companyId: 512
    },
    {
        id: "K06feNpAYEUXtgLs0e9k3AqUHM73",
        name: "Lucas Alonso",
        email: "lucas@blizzardpro.com",
        companyId: 512
    },
];

export interface PDProjectMediaInterface {
    id: number,
    name: string,
    firebaseName: string, // so files aren't overwritten by accident
    url: string,
    category: string,
    createdAt: Date
}

export const buildProjectMedia = (mediaData) : PDProjectMediaInterface => {
    return {
        id: mediaData.id,
        name: mediaData.name,
        firebaseName: mediaData.firebase_name,
        url: mediaData.url,
        category: mediaData.category,
        createdAt: new Date(mediaData.created_at)
    }; 
}

export interface PDProjectTaskInterface {
    id?: number,
    name: string,
    taskStatus: PDProjectStatusInterface,
    projectStatus: PDProjectStatusInterface,
    order?: number,
    data?: string,
    type: PDProjectTaskTypes,
    notes?: string,
}

export const PDProjectTaskStatuses: PDProjectStatusInterface[] = [
    {id: 1, name: "Not Started"},
    {id: 2, name: "In Progress"},
    {id: 3, name: "Done"},
    {id: 4, name: "Skipped"}
];

const getTaskStatusById = (statusId: number) => {
    return PDProjectTaskStatuses.find((s) => s.id == statusId);   
}

const getProjectStatusById = (statusId: number) => {
    return PDProjectStatuses.find((s) => s.id == statusId);   
}

export const buildProjectTask = (taskData) : PDProjectTaskInterface => {
    return {
        id: taskData.id,
        name: taskData.name,
        taskStatus: getTaskStatusById(taskData.task_status_id),
        projectStatus: getProjectStatusById(taskData.project_status_id),
        data: taskData.data,
        type: taskData.type,
        notes: taskData.notes
    };
}

export enum PDProjectTaskTypes {
    Checkbox = "Checkbox",
    Media = "Media",
    Number = "Number",
    Link = "Link"
}

export const PDGenericTaskList: PDProjectTaskInterface[] = [
    buildProjectTask({name: "Factory Manual Received", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Get Price", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Number}), // Number
    buildProjectTask({name: "Sample Ordered", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Initial Review (Gut Check)", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Test Functionality", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Test Menu", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Do Photometric Test", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Request IES Files", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Request 3D Files", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Tech Review", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Link}), // URL Link
    buildProjectTask({name: "Change List Compiled", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Second Gut Check", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Checkbox}), // Check - Look at the change list, look at the price. Is the juice worth the squeeze
    buildProjectTask({name: "Assign SKUs / UPCs", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Finish DMX Changes", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Finish Functional Changes", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Create Rendered Images", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Media}), // Check
    buildProjectTask({name: "Finish Menu Changes", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Finish RDM Changes", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Get Packaging Dims/Dielines", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Create Silk Screens", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Create Logo", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Create Manual", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Create Inner Box", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Create Outer Box", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Finish Packaging", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Finish Designs", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Submit PO", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Media}), // Media?
    buildProjectTask({name: "Order Masterpack", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Checkbox}), // Check
    buildProjectTask({name: "Create/Obtain GDTF Files", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Media}), // Media
    buildProjectTask({name: "Tech Masterpack Review", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Link}), // URL Link
];

export const PDMassReworkTaskList: PDProjectTaskInterface[] = [
    buildProjectTask({name: "Identify Issue", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Media, }),
    buildProjectTask({name: "Establish Priority Level", task_status_id: 1, project_status_id: 1, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Create an Internal Modification Ticket in Freshdesk", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Link}),
    buildProjectTask({name: "Create BoM", task_status_id: 1, project_status_id: 2, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Create an Example Repair Fixture", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Checkbox}),
    buildProjectTask({name: "Document the Repair Process", task_status_id: 1, project_status_id: 3, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Do the Work", task_status_id: 1, project_status_id: 4, type: PDProjectTaskTypes.Checkbox}),
    buildProjectTask({name: "Account for Actual Cost", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Summarize and Report on Project", task_status_id: 1, project_status_id: 5, type: PDProjectTaskTypes.Media})
];

export const HardCodedHelperText = [
    {name: "Identify Issue", text: "Clearly identify the issue with the fixture to be modified. Troubleshoot and test until full confidence is reached that this will fix the overall issue."},
    {name: "Establish Priority Level", text: "Establish a timeline and priority level for the project to be completed. This should account for labor, parts procurement and final testing and QC."},
    {name: "Create an Internal Modification Ticket in Freshdesk", text: "Create an Internal Modification Ticket In Freshdesk. This ticket will be used for all communication throughout the project. Emails, documents, sales orders, pictures, and notes should all be in this ticket."},
    {name: "Create BoM", text: "Create a BOM for every piece ordered and also the tools required to execute the project."},
    {name: "Create an Example Repair Fixture", text: "Complete the modification on one or two fixtures that can be used as a source of truth for all future repairs. Plan to keep this fixture available for future reference."},
    {name: "Document the Repair Process", text: "Document the modification using pictures, parts used, and specific techniques if necessary. This document should be made available to all parties doing the repair."},
    {name: "Do the Work", text: "Schedule the workers. Complete the Modification."},
    {name: "Account for Actual Cost", text: "Account for ALL labor and parts used on a REPAIR ORDER Sales Order. Account for troubleshooting time and tooling."},
    {name: "Summarize and Report on Project", text: "Write a brief summary of the project after it is completed that outlines all costs. Include labor, parts, tools, and any lost revenue. Submit the report to management and also the vendor that sent the failed product."}
]

export interface PDProjectChecklistInterface {
    id?: number,
    type: PDProjectChecklistTypes,
    tasks: PDProjectTaskInterface[],
    baubleId?: number,
    createdAt?: Date,
}

export enum PDProjectChecklistTypes {
    Progress = "Progress",
    Initial = "Initial",
    // PreProduction = "PreProduction",
    FRMP = "FRMP"
}

export const buildProjectChecklist = (data): PDProjectChecklistInterface => {
    return {
        id: data.id,
        type: data.checklist_type,
        tasks: data.tasks.map((task) => buildProjectTask(task)).sort((a, b) => a.id - b.id),
        baubleId: data.bauble_id,
        createdAt: new Date(data.created_at),
    }
}

export const DefaultPDProgressChecklist: PDProjectChecklistInterface = {
    type: PDProjectChecklistTypes.Progress,
    tasks: [...PDGenericTaskList]
}

export const DefaultPDProgressChecklistMassRework: PDProjectChecklistInterface = {
    type: PDProjectChecklistTypes.Progress,
    tasks: [...PDMassReworkTaskList]
}

export const PDTechReviewTypes: PDProjectChecklistTypes[] = [
    PDProjectChecklistTypes.Initial,
    // PDProjectChecklistTypes.PreProduction,
    PDProjectChecklistTypes.FRMP,
];

export const PDInitialReviewTasks: PDProjectTaskInterface[] = [
    buildProjectTask({name: "RMA Link", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Link}),
    buildProjectTask({name: "Overview", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Electrical", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Physical", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Functionality", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Stress Test", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media})
    // // Operations
    // buildProjectTask({name: "Sample Packaging - Condition of Box", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Sample Packaging - Condition of Foam", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Master Pack - # Recommendation", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Inner Carton Size", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Inner Carton Orientation", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Master Pack Orientation", task_status_id: 1, project_status_id: 7, type: PDProjectTaskTypes.Media}),
    // // General
    // buildProjectTask({name: "Software Bugs", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "DMX In / Out", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "M / S Modes", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "User Interface Assessment", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // // DMX Profiles
    // buildProjectTask({name: "Test DMX Profiles", task_status_id: 1, project_status_id: 9, type: PDProjectTaskTypes.Media}),
    // // Fixture Sound Levels
    // buildProjectTask({name: "Noise Level - PWM", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Fans", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Motors", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Other", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // // Factory Manual Accuracy
    // buildProjectTask({name: "Factory Manual - Menus", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Factory Manual - Profiles", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Factory Manual - Other", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    // // Safety Assessment
    // buildProjectTask({name: "Electrical (Wiring Gauge & Connections)", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Physical / Integrity", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Flammability Testing", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Thermal Management", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Safety Loop", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // // Hardware
    // buildProjectTask({name: "Assess Soldering Quality", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Mains and Ground Wiring Correct", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Power Connections Correct", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Fasteners Correctly Sized", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Wiring Harness Quality & Routing", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Yoke - Hole Size, Quality, Issues", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Lubrication", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Determine Maintenance Schedule & Requirements", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // // Torture Test
    // buildProjectTask({name: "8 Hour Auto Programs", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Moving Head - Hang on Truss", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Temperature Readings", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "IP Rating", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    // // Other
    // buildProjectTask({name: "Examine Accessories for Fit and Function", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Spare Parts List", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Wish List", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
];

export const PDPreProductionReviewTasks: PDProjectTaskInterface[] = [
    // DMX Profiles
    buildProjectTask({name: "Test DMX Profiles", task_status_id: 1, project_status_id: 9, type: PDProjectTaskTypes.Media}),
    // Fixture Sound Levels
    buildProjectTask({name: "Noise Level - PWM", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Noise Level - Fans", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Noise Level - Motors", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Noise Level - Other", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // Safety Assessment
    buildProjectTask({name: "Electrical (Wiring Gauge & Connections)", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Physical / Integrity", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Flammability Testing", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // Torture Test
    buildProjectTask({name: "8 Hour Auto Programs", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Moving Head - Hang on Truss", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Temperature Readings", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "IP Rating", task_status_id: 1, project_status_id: 14, type: PDProjectTaskTypes.Media}),
    // General
    buildProjectTask({name: "Silkscreen Accuracy", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Carton Information Accuracy", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Fixture Ready for Production", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "User Interface Assessment", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // Hardware
    buildProjectTask({name: "Power Connections Correct", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // Blizzard Manual Accuracy
    buildProjectTask({name: "Blizzard Manual - Menus", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Blizzard Manual - Profiles", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Blizzard Manual - Other", task_status_id: 1, project_status_id: 11, type: PDProjectTaskTypes.Media}),
    // Other
    buildProjectTask({name: "Examine Accessories for Fit and Function", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Spare Parts List", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Recommended Warranty Time", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
];

export const PDFRMPReviewTasks: PDProjectTaskInterface[] = [
    buildProjectTask({name: "RMA Link", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Link}),
    buildProjectTask({name: "Overview", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Verify", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    buildProjectTask({name: "Parts Pictures", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "OK To Ship", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // // General
    // buildProjectTask({name: "Silkscreen Accuracy", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Software Bugs", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "DMX In / Out", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "M / S Modes", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "User Interface Assessment", task_status_id: 1, project_status_id: 8, type: PDProjectTaskTypes.Media}),
    // // Fixture Sound Levels
    // buildProjectTask({name: "Noise Level - PWM", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Fans", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Motors", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Noise Level - Other", task_status_id: 1, project_status_id: 10, type: PDProjectTaskTypes.Media}),
    // // Safety Assessment
    // buildProjectTask({name: "Electrical (Wiring Gauge & Connections)", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Physical / Integrity", task_status_id: 1, project_status_id: 12, type: PDProjectTaskTypes.Media}),  
    // // DMX Profiles
    // buildProjectTask({name: "Test DMX Profiles", task_status_id: 1, project_status_id: 9, type: PDProjectTaskTypes.Media}),
    // // Hardware
    // buildProjectTask({name: "Power Connections Correct", task_status_id: 1, project_status_id: 13, type: PDProjectTaskTypes.Media}),
    // // Other
    // buildProjectTask({name: "Photograph Parts", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Compare to PreProduction Sample", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Packaging Adequate", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
    // buildProjectTask({name: "Rework Necessary", task_status_id: 1, project_status_id: 15, type: PDProjectTaskTypes.Media}),
];

export const PDProjectTypes: PDProjectTypeInterface[] = [
    {id: 1, name: "New Product"},
    {id: 2, name: "New Internal Product"},
    {id: 3, name: "Mass Rework"}
];

export interface PDProjectTypeInterface {
    id: number,
    name: string
}

export const getPDProjectType = (typeId): PDProjectTypeInterface => {
    let type = PDProjectTypes.find((t) => t.id == typeId);
    return type ? type : {id: 1, name: "New Product"};
}