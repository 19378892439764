import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { productFromHasuraObject, ProductInterface, ProductMediaInterface } from 'interfaces/Product';
import { IconButton } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import { makeAxiosCall } from "utils";


interface ProductEditorFavoriteMenuPropsInterface {
    initialImage: ProductMediaInterface,
    product: ProductInterface,
    setProduct: Function
  }

  
export default function ProductEditorMediaFavoriteMenu({initialImage, product, setProduct} : ProductEditorFavoriteMenuPropsInterface) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [image, setImage] = React.useState<ProductMediaInterface>(initialImage);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buildSmallUrl = (bigImageUrl: string) => {
    let smallUrl = "";
    
    const smallUrlEnd = "_1920x1920.webp";
    if(bigImageUrl.indexOf(smallUrlEnd) > -1 || bigImageUrl.indexOf("_1440x1440.webp") > -1) {
      smallUrl = bigImageUrl;
    } else {
        let dotIndex = bigImageUrl.lastIndexOf(".");
        smallUrl = bigImageUrl.slice(0, dotIndex) + smallUrlEnd;

        let queryIndex = bigImageUrl.lastIndexOf("?");
        if(queryIndex > 0) smallUrl += bigImageUrl.slice(queryIndex);
    }

    return smallUrl;
  };

  
  const handleSetFeatured = () => {
    setProduct({...product, imageUrl: image.url});
    let smallUrl: string = buildSmallUrl(image.url);


    makeAxiosCall("POST", "product-media/set-featured-image", {
        productId: product.id,
        url: smallUrl
    }).then((response) => {
        if(response.error) {
            console.log(response);
        }
    });
  };


  const renderShowOnWebflowMenuItem = () => {
    if(!product.collections || !product.collections.length) return (<MenuItem disabled={true}>Product not in Webflow</MenuItem>);   
    if(image.public) {
        return (<MenuItem onClick={() => updatePublicStatus(false)}>Remove from Webflow</MenuItem>);   
    } else {
        return (<MenuItem onClick={() => updatePublicStatus(true)}>Add to Webflow</MenuItem>);   
    }
  };

  const renderWebflowFieldMenuItem = (fieldName: string, fieldSlug: string) => {
    if(product.webflowFields) {
        let imageField = product.webflowFields.find((f) => f.slug == fieldSlug);
        if(imageField) {
            if(imageField.value == image.url) {
                return (<MenuItem disabled={true}>Current {fieldName}</MenuItem>);   
            } else {
                return (<MenuItem onClick={() => {
                    saveWebflowField(fieldSlug);
                }}>Set as {fieldName}</MenuItem>);   
            }
        }
    }
    return null;
  }

  const updatePublicStatus = (setPublic: boolean) => {
    let updatedProduct: ProductInterface = {...product, media: []};
    const sortedMedia = product.media.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));
    let allPublicMedia = [];
    for (let mi = 0; mi < sortedMedia.length; mi++) {
        const m = sortedMedia[mi];
        
        if(m.id != image.id) {
            updatedProduct.media.push(m);
            if(m.public && m.type.name == "image") {
                let urlSafe = buildSmallUrl(m.url);
                if(m.url.indexOf("https://storage.googleapis.com/dealerportal-2fb18.appspot.com/products/") > 0) {
                    urlSafe = encodeURI(m.url);
                }
                allPublicMedia.push(urlSafe);
            }
        } else {
            let updatedImage = {
                ...m,
                public: setPublic
            };
            updatedProduct.media.push(updatedImage);
            setImage(updatedImage);
            if(setPublic) {
                let urlSafe = m.url;
                if(m.url.indexOf("https://storage.googleapis.com/dealerportal-2fb18.appspot.com/products/") > 0) {
                    urlSafe = encodeURI(m.url);
                }
                allPublicMedia.push(urlSafe);
            };
        }
    }
    setProduct(updatedProduct);

    saveImage(setPublic, allPublicMedia);
  }

  const saveWebflowField = (fieldSlug: string) => {
    let updatedProduct: ProductInterface = {...product, webflowFields: []};
    for (let wfi = 0; wfi < product.webflowFields.length; wfi++) {
        const singleField = product.webflowFields[wfi];
        if(singleField.slug != fieldSlug) {
            updatedProduct.webflowFields.push(singleField);
        } else {
            let smallUrl: string = buildSmallUrl(image.url);
            if(smallUrl.indexOf("https://storage.googleapis.com/dealerportal-2fb18.appspot.com/products/") > 0) {
              smallUrl = encodeURI(smallUrl);
            }
            updatedProduct.webflowFields.push({
                ...singleField,
                value: smallUrl
            });
        }
    }
    setProduct(updatedProduct);

    
    saveProduct(updatedProduct);
  }

  const saveImage = (setPublic: boolean, allPublicMedia: string[]) => {
    const smallUrlEnd = "_1920x1920.webp";
    let smallImages = [];
    for (let ii = 0; ii < allPublicMedia.length; ii++) {
        const imageUrl = allPublicMedia[ii];
        if(imageUrl.indexOf(smallUrlEnd) > -1 || imageUrl.indexOf("_1440x1440.webp") > -1) {
            smallImages.push(imageUrl);
        } else {
            let smallUrl = buildSmallUrl(imageUrl);
            
            smallImages.push(smallUrl);
        }
    }
    makeAxiosCall("POST", `product-media/set-public`, {
        publicImages: smallImages,
        changingImageId: image.id,
        setPublic: setPublic
    }).then((response) => {
        console.log(response);
    })
  }

  const saveProduct = (updatedProduct: ProductInterface) => {
    makeAxiosCall("POST", `product/${product.id}`, updatedProduct).then((response) => {
        if(response.error) {
            console.log(response);
        } else {
          setProduct(productFromHasuraObject(response.data))
        }
    })
  }

  let favorited = image.url == product.imageUrl || buildSmallUrl(image.url) == product.imageUrl;

  return (
    <div>
        <IconButton
            aria-controls="favorite-menu"
            aria-haspopup="true"
            style={{
                position: "relative",
                right: 20,
            }}
            onClick={handleClick}
        >
            {
                favorited ? 
                    <Star style={{ color: "gold"}} /> :
                    <StarBorder style={{ color: "#828282" }} />
            }
        </IconButton>
      <Menu
        id="favorite-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Close</MenuItem>
        <MenuItem disabled={favorited} onClick={handleSetFeatured}>{favorited ? "Featured Image" : "Set Featured"}</MenuItem>
        {renderShowOnWebflowMenuItem()}
        {renderWebflowFieldMenuItem("Hero 1 Image", "hero-section-1-image")}
        {renderWebflowFieldMenuItem("Hero 2 Image", "hero-section-2-image")}
        {renderWebflowFieldMenuItem("Hero 3 Image", "hero-section-3-image")}
      </Menu>
      
    </div>
  );
}