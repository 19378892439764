import React, { useState, useContext } from "react";
import _ from "lodash";
import { Grid, Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemText, Avatar, TextField, Switch, FormControlLabel, ListItemAvatar, Button, Modal, Select, OutlinedInput, MenuItem } from "@material-ui/core";
import { UserContext } from "context/User";
import { useNavigate } from "react-router-dom";
import Loading from "components/utils/Loading";
import { Index, List, WindowScroller } from "react-virtualized";
import { ExpandMore, ListAlt } from "@material-ui/icons";
import { makeAxiosCall } from "utils";
import { buildFeedbackSearchLists, DemoFeedbackInterface, DemoFeedbackProductInterface, DemoFeedbackProductReportInterface, DemoFeedbackRepReportInterface, DemoFeedbackUserReportInterface } from "interfaces/DemoFeedback";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";

import "scss/components/Demos/DemoFeedbackList.scss";
import ModalBasic from "components/utils/ModalBasic";
import { BasicCompanyInterface } from "interfaces/Company";
import { BasicUserInterface } from "interfaces/User";

interface DemoFeedbackListProps {
    allFeedback: Array<DemoFeedbackInterface>,
    allReps: Array<BasicCompanyInterface>,
    allSalesTeamMembers: Array<BasicUserInterface>,
    reloadFeedback: Function
}

const DemoFeedbackList = ({ allFeedback, allReps, allSalesTeamMembers, reloadFeedback }: DemoFeedbackListProps) => {
    const { currentUser, internalUser } = useContext(UserContext);
    const [searchInput, setSearchInput] = useState<string>(localStorage.getItem("last-search-demo-feedback-list") ?? "");
    const [feedbackList, setFeedbackList] = React.useState<DemoFeedbackInterface[]>([]);
    const [selectedFeedback, setSelectedFeedback] = React.useState<DemoFeedbackInterface>();
    const [showMoreInfoModal, setShowMoreInforModal] = React.useState<boolean>();
    const [allRepFeedbackList, setAllRepFeedbackList] = React.useState<DemoFeedbackRepReportInterface[]>([]);
    const [allUserFeedbackList, setAllUserFeedbackList] = React.useState<DemoFeedbackUserReportInterface[]>([]);
    const [allProductFeedbackList, setAllProductFeedbackList] = React.useState<DemoFeedbackProductReportInterface[]>([]);
    const [visibleRepFeedbackList, setVisibleRepFeedbackList] = React.useState<DemoFeedbackRepReportInterface[]>([]);
    const [visibleUserFeedbackList, setVisibleUserFeedbackList] = React.useState<DemoFeedbackUserReportInterface[]>([]);
    const [visibleProductFeedbackList, setVisibleProductFeedbackList] = React.useState<DemoFeedbackProductReportInterface[]>([]);
    const [loading, setLoading] = React.useState(true);
    //   const [showByProduct, setShowByProduct] = React.useState(false);
    const [modalAccordionSectionSelected, setModalAccordionSectionSelected] = React.useState<string>("who");
    const [expandedProducts, setExpandedProducts] = React.useState({});
    const [expandedReps, setExpandedReps] = React.useState({});
    const [currentView, setCurrentView] = React.useState("demo-feedback");
    const [expandedUsers, setExpandedUsers] = React.useState({});
    const [filterStartDate, setFilterStartDate] = React.useState<Date>(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [filterEndDate, setFilterEndDate] = React.useState<Date>(new Date());

    const windowScrollerRef = React.useRef<WindowScroller>();
    const virtualizedListRef = React.useRef<List>();


    React.useEffect(() => {
        if (allFeedback) {
            sortFeedback();
        }
    }, [allFeedback, allReps, allSalesTeamMembers]);

    React.useEffect(() => {
        filterFeedback();
    }, [searchInput, filterStartDate, filterEndDate, loading, currentView]);

    const deleteFeedback = async (badFeedback: DemoFeedbackInterface) => {

        let result = await makeAxiosCall(
            "delete",
            "demo-feedback/" + badFeedback.id
        ).catch(error => {
            console.error(error);
        });

        if (result && result.data) {
            reloadFeedback();
            setShowMoreInforModal(false);
        }
    }

    const sortFeedback = async () => {
        console.log("sortFeedback happen")

        let { fullFeedbackList, fullRepFeedbackList, fullUserFeedbackList, fullProductFeedbackList } = buildFeedbackSearchLists(
            allFeedback,
            allReps, // These should be ok raw
            allSalesTeamMembers
        );

        setFeedbackList(fullFeedbackList);
        setAllRepFeedbackList(fullRepFeedbackList);
        setAllUserFeedbackList(fullUserFeedbackList);
        setAllProductFeedbackList(fullProductFeedbackList);
        setVisibleProductFeedbackList(fullProductFeedbackList);
        setVisibleRepFeedbackList(fullRepFeedbackList);
        setVisibleUserFeedbackList(fullUserFeedbackList);
        setLoading(false);
        filterFeedback();
    }

    const showSingleFeedback = async (feedback: DemoFeedbackInterface) => {
        setSelectedFeedback(feedback);
        setShowMoreInforModal(true);
    };

    const filterFeedback = () => {
        let cleanInput = searchInput.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");
        let newProductList: DemoFeedbackProductReportInterface[] = structuredClone(allProductFeedbackList);
        let newRepList: DemoFeedbackRepReportInterface[] = structuredClone(allRepFeedbackList);
        let newUserList: DemoFeedbackUserReportInterface[] = structuredClone(allUserFeedbackList);

        if (cleanInput.length > 0) {
            if (currentView == "products") {
                newProductList = newProductList.filter((pf) => pf.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "").indexOf(cleanInput) > -1);
            } else {
                if (internalUser) {
                    newRepList = newRepList.filter((rf) => rf.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "").indexOf(cleanInput) > -1);

                } else {
                    newUserList = newUserList.filter((uf) => uf.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "").indexOf(cleanInput) > -1);
                }
            }
        }

        if (currentView == "products") {
            for (let pli = 0; pli < newProductList.length; pli++) {
                const productListItem = newProductList[pli];
                for (let plri = 0; plri < productListItem.reps.length; plri++) {
                    const productRepListItem = productListItem.reps[plri];
                    for (let plrui = 0; plrui < productRepListItem.users.length; plrui++) {
                        const productRepUserListItem = productRepListItem.users[plrui];
                        productRepUserListItem.demoFeedback = productRepUserListItem.demoFeedback.filter((f: DemoFeedbackInterface) => f.dateOfDemo >= filterStartDate && f.dateOfDemo <= filterEndDate);
                    }
                    productRepListItem.users = productRepListItem.users.filter((u) => u.demoFeedback.length > 0);
                }
                productListItem.reps = productListItem.reps.filter((r) => r.users.length > 0);

                for (let plui = 0; plui < productListItem.repUsers.length; plui++) {
                    const productUserListItem = productListItem.repUsers[plui];

                    productUserListItem.demoFeedback = productUserListItem.demoFeedback.filter((f) => f.dateOfDemo >= filterStartDate && f.dateOfDemo <= filterEndDate);
                }
                productListItem.repUsers = productListItem.repUsers.filter((u) => u.demoFeedback.length > 0);
            }

            newProductList = newProductList.filter((p) => p.repUsers.length > 0 && p.reps.length > 0);
        } else {
            if (internalUser || currentUser.company.companyType == "Internal") {
                for (let rli = 0; rli < newRepList.length; rli++) {
                    const repListItem = newRepList[rli];

                    for (let ruli = 0; ruli < repListItem.users.length; ruli++) {
                        console.log(repListItem.users[ruli].demoFeedback)
                        repListItem.users[ruli].demoFeedback = repListItem.users[ruli].demoFeedback.filter(
                            (f) => 
                                f.dateOfDemo >= filterStartDate && 
                                f.dateOfDemo <= filterEndDate &&
                                f.formType == currentView
                        );
                    }
                    repListItem.users.sort((a, b) => b.demoFeedback.length - a.demoFeedback.length);
                    repListItem.numberDemoed = repListItem.users.reduce((a, b) => a + b.demoFeedback.length, 0);
                }
                newRepList.sort((a, b) => b.numberDemoed - a.numberDemoed);

            } else {

                for (let ruli = 0; ruli < newUserList.length; ruli++) {
                    newUserList[ruli].demoFeedback = newUserList[ruli].demoFeedback.filter(
                        (f) => 
                            f.dateOfDemo >= filterStartDate && 
                            f.dateOfDemo <= filterEndDate &&
                            f.formType == currentView
                    );
                }
                newUserList = newUserList.filter((uf) => uf.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "").indexOf(cleanInput) > -1);
                newUserList.sort((a, b) => b.demoFeedback.length - a.demoFeedback.length);
            }
        }


        resetAccordions();
        setVisibleProductFeedbackList(newProductList);
        setVisibleRepFeedbackList(newRepList);
        setVisibleUserFeedbackList(newUserList);
    }

    const resetAccordions = () => {
        setExpandedProducts({});
        setExpandedReps({});
        setExpandedUsers({});

        if (virtualizedListRef.current) {
            virtualizedListRef.current.recomputeRowHeights()
            virtualizedListRef.current.forceUpdate();
        }
    }

    const toggleProductAccordian = (listKey: string, shouldExpand: boolean, index: number) => {
        setExpandedProducts({ ...expandedProducts, [listKey]: shouldExpand });
        virtualizedListRef.current.recomputeRowHeights(index);
        virtualizedListRef.current.forceUpdate();
    };

    const toggleRepAccordian = (listKey: string, shouldExpand: boolean, index: number) => {
        setExpandedReps({ ...expandedReps, [listKey]: shouldExpand });
        virtualizedListRef.current.recomputeRowHeights(index);
        virtualizedListRef.current.forceUpdate();
    };

    const toggleUserAccordian = (listKey: string, shouldExpand: boolean, index: number) => {
        setExpandedUsers({ ...expandedUsers, [listKey]: shouldExpand });
        virtualizedListRef.current.recomputeRowHeights(index);
        virtualizedListRef.current.forceUpdate();
    };

    const renderViewDropdown = () => {
        return (
            <Select
                key={"view-dropdown"}
                value={currentView}
                onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                    setCurrentView(e.target.value);
                    resetAccordions();
                }}
                input={<OutlinedInput />}
                style={{ minWidth: 200, textAlign: "left" }}
            >
                <MenuItem
                    key={"view-dropdown-demos"}
                    value={"demo-feedback"}
                >
                    <i className={currentView == "demo-feedback" ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: currentView == "demo-feedback" ? "green" : "grey" }}></i>
                    Demos
                </MenuItem>
                <MenuItem
                    key={"view-dropdown-visits"}
                    value={"visit"}
                >
                    <i className={currentView == "visit" ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: currentView == "visit" ? "green" : "grey" }}></i>
                    Visits
                </MenuItem>
                <MenuItem
                    key={"view-dropdown-phone"}
                    value={"phone-call"}
                >
                    <i className={currentView == "phone-call" ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: currentView == "phone-call" ? "green" : "grey" }}></i>
                    Phone Calls
                </MenuItem>
                <MenuItem
                    key={"view-dropdown-product"}
                    value={"products"}
                >
                    <i className={currentView == "products" ? "far fa-check green" : "far fa-close grey"} style={{ margin: 5, marginRight: 10, fontSize: "1.2em", color: currentView == "products" ? "green" : "grey" }}></i>
                    Products
                </MenuItem>
            </Select>
        );
    };

    const buildFilters = () => {
        return (
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} md={3}>
                    <DatePicker
                        style={{ width: "100%" }}
                        margin="normal"
                        inputVariant="outlined"
                        format={"MM/DD/yy"}
                        label="Start Date"
                        views={["date"]}
                        disableFuture={true}
                        value={filterStartDate}
                        onChange={(date: moment.Moment) => {
                            setFilterStartDate(date.toDate())
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <DatePicker
                        style={{ width: "100%" }}
                        margin="normal"
                        inputVariant="outlined"
                        format={"MM/DD/yy"}
                        label="End Date"
                        views={["date"]}
                        disableFuture={true}
                        value={filterEndDate}
                        onChange={(date: moment.Moment) => {
                            setFilterEndDate(date.toDate())
                        }}
                    />
                </Grid>
                <Grid item xs={8} md={4}>
                    <TextField
                        style={{ width: "100%" }}
                        margin="normal"
                        label={currentView == "products" ? "Search Products" : (internalUser ? "Search Reps" : "Search Users")}
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    {renderViewDropdown()}
                </Grid>
            </Grid>
        );
    }

    const renderProduct = (prod: DemoFeedbackProductReportInterface, leaf: boolean, index: number) => {
        return (
            <Accordion
                key={"feedback-list-prod-" + prod.listKey}
                TransitionProps={{ timeout: 0 }}
                style={{ backgroundColor: ((currentView != "products" && !internalUser) ? "whitesmoke" : "white") }}
                elevation={2}
                expanded={expandedProducts[prod.listKey] ? true : false}
                onChange={(e, shouldExpand) => {
                    toggleProductAccordian(prod.listKey, shouldExpand, index);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">
                    <Avatar alt={prod.name} src={prod.imageUrl} style={{
                        marginRight: 16
                    }}>
                        {leaf ? prod.demoFeedback.length : (internalUser ? prod.reps.length : prod.repUsers.length)}
                    </Avatar>
                    {prod.name + " (" + (leaf ? prod.demoFeedback.length : prod.numberDemoed) + ")"}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    {
                        leaf ? _.orderBy(prod.demoFeedback, ['name'], ['desc']).map((f) => renderFeedback(f, index)) :
                            (
                                internalUser ?
                                    _.orderBy(prod.reps, ['name'], ['desc']).map((r) => renderRep(r, index)) :
                                    _.orderBy(prod.repUsers, ['name'], ['desc']).map((u) => renderUser(u, index))
                            )
                    }
                </AccordionDetails>
            </Accordion>
        );
    }

    const renderRep = (rep: DemoFeedbackRepReportInterface, index: number) => {
        return (
            <Accordion
                key={"feedback-list-rep-" + rep.id}
                TransitionProps={{ timeout: 0 }}
                style={{ backgroundColor: ((currentView == "products" && internalUser) ? "whitesmoke" : "white") }}
                elevation={2}
                expanded={expandedReps[rep.listKey] ? true : false}
                disabled={rep.numberDemoed == 0}
                onChange={(e, shouldExpand) => {
                    toggleRepAccordian(rep.listKey, shouldExpand, index);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none" }} className="line-item">
                    {rep.name + " (" + rep.numberDemoed + ")"}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    {
                        _.orderBy(rep.users, ['name'], ['desc']).map((u: DemoFeedbackUserReportInterface) => renderUser(u, index))
                    }
                </AccordionDetails>
            </Accordion>
        );
    }

    const renderUser = (user: DemoFeedbackUserReportInterface, index: number) => {
        console.log(user)
        return (
            <Accordion
                key={"feedback-list-user-" + user.id + index}
                TransitionProps={{ timeout: 0 }}
                disabled={(currentView != "products" && user.demoFeedback.length == 0) || (currentView == "products" && user.products.length == 0 && user.demoFeedback.length == 0)}
                style={{ backgroundColor: (internalUser ? (currentView == "products" ? "white" : "whitesmoke") : (currentView == "products" ? "whitesmoke" : "white")) }}
                elevation={2}
                expanded={expandedUsers[user.listKey] ? true : false}
                onChange={(e, shouldExpand) => {
                    toggleUserAccordian(user.listKey, shouldExpand, index);
                }}
            >
                <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none" }} className="line-item">
                    {user.name + " (" + (user.demoFeedback.length) + ")"}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    {
                        currentView == "demo-feedback" ? 
                            _.orderBy(user.products, ['name'], ['desc']).map((f) => renderProduct(f, true, index)) :
                            _.orderBy(user.demoFeedback, ['name'], ['desc']).map((f) => renderFeedback(f, index))
                    }
                </AccordionDetails>
            </Accordion>
        );
    }

    const renderFeedback = (feedback: DemoFeedbackInterface, index: number) => {
        return (
            <ListItem
                key={"feedback-list-feedback-" + feedback.listKey}
                onClick={() => showSingleFeedback(feedback)}
                className={"feedback-list-list-item"}
            >
                <ListItemAvatar>
                    <ListAlt />
                </ListItemAvatar>
                <ListItemText
                    primary={buildFeedbackListItemHeader(feedback)}
                    secondary={
                        <React.Fragment>
                            {feedback.dateOfDemo.toLocaleDateString()}
                        </React.Fragment>
                    }
                    style={{ cursor: "pointer" }}
                />
                <ListItemAvatar>
                    <i className="fas fa-eye blue"></i>
                </ListItemAvatar>
            </ListItem>
        );
    }

    const buildFeedbackListItemHeader = (feedback: DemoFeedbackInterface): string => {
        let builtHeader = "";
        switch(feedback.formType) {
            case "demo-feedback":
                builtHeader += "Demoed " + feedback.productsDemoed.length + " products to ";
                break;
            case "phone-call":
                builtHeader += "Had a phone call with ";
                break;
            case "visit":
                builtHeader += "Was visited by ";
                break;
        }

        if (feedback.demoForExistingReseller) {
            builtHeader += feedback.targetReseller.name;
        } else {
            builtHeader += feedback.demoForExistingReseller ? feedback.targetReseller.name : ((feedback.potentialResellerName ? feedback.potentialResellerName : "") + " (a potential reseller)");
        }

        return builtHeader;
    }

    const renderPlaceholder = () => {
        return <h3 className="body-message center-text">No demo feedback meet your search parameters.</h3>;
    }

    const renderRow = (index: number, key: string, style) => {
        if (currentView == "products") {
            return (
                <div key={key} style={style} className={"single-product-line"}>
                    {renderProduct(visibleProductFeedbackList[index], false, index)}
                </div>
            )
        } else {
            if (internalUser) {
                return (
                    <div key={key} style={style} className={"single-product-line"}>
                        {renderRep(visibleRepFeedbackList[index], index)}
                    </div>
                );
            } else {
                return (
                    <div key={key + "-user"} style={style} className={"single-product-line"}>
                        {renderUser(visibleUserFeedbackList[index], index)}
                    </div>
                );
            }
        }
    }

    const calculateRowHeight = (listType: string, rowIndex: number) => {
        let calculatedHeight = 0;

        const singleProductRowHeight = 82;
        const productListMarginTotal = 56;

        const singleDemoRowHeight = 72;
        const demoListMarginTotal = 24;

        const singleUserRowHeight = 82;
        const userListMarginTotal = 56;

        const singleRepRowHeight = 82;
        const repListMarginTotal = 56;

        switch (listType) {
            case "products":
                calculatedHeight += (productListMarginTotal / visibleProductFeedbackList.length) + singleProductRowHeight;
                // calculatedHeight += productListMarginTotal;
                // for (let ri = 0; ri < Object.keys(expandedProducts).length; ri++) {
                // const productId: number = parseInt(Object.keys(expandedProducts)[ri]);
                const product = visibleProductFeedbackList[rowIndex];

                if (expandedProducts[product.listKey]) {
                    if (!internalUser) {
                        calculatedHeight += ((product.repUsers.length * singleUserRowHeight) + userListMarginTotal);
                        for (let prui = 0; prui < product.repUsers.length; prui++) {
                            const productUser = product.repUsers[prui];

                            if (expandedUsers[productUser.listKey]) {
                                calculatedHeight += ((productUser.demoFeedback.length * singleDemoRowHeight) + demoListMarginTotal);
                            }
                        }
                    } else {
                        calculatedHeight += ((product.reps.length * singleRepRowHeight) + repListMarginTotal);
                        for (let pri = 0; pri < product.reps.length; pri++) {
                            const productRep = product.reps[pri];

                            if (expandedReps[productRep.listKey]) {
                                calculatedHeight += ((productRep.users.length * singleUserRowHeight) + userListMarginTotal);
                                for (let prui = 0; prui < productRep.users.length; prui++) {
                                    const productUser = productRep.users[prui];

                                    if (expandedUsers[productUser.listKey]) {
                                        calculatedHeight += ((productUser.demoFeedback.length * singleDemoRowHeight) + demoListMarginTotal);
                                    }
                                }
                            }
                        }
                    }
                }
                // }

                break;
            case "reps":
                calculatedHeight += ((repListMarginTotal / visibleRepFeedbackList.length) + singleRepRowHeight);
                // for (let ri = 0; ri < Object.keys(expandedReps).length; ri++) {

                const rep = visibleRepFeedbackList[rowIndex];

                if (expandedReps[rep.listKey]) {
                    calculatedHeight += ((rep.users.length * singleUserRowHeight) + userListMarginTotal);
                    for (let pri = 0; pri < rep.users.length; pri++) {
                        const repUser = rep.users[pri];

                        if (expandedUsers[repUser.listKey]) {
                            calculatedHeight += ((repUser.products.length * singleProductRowHeight) + productListMarginTotal);
                            for (let prui = 0; prui < repUser.products.length; prui++) {
                                const userProduct = repUser.products[prui];

                                if (expandedProducts[userProduct.listKey]) {
                                    calculatedHeight += ((userProduct.demoFeedback.length * singleDemoRowHeight) + demoListMarginTotal);
                                }
                            }
                        }
                    }
                }
                // }
                break;
            case "users":
            default:
                calculatedHeight += ((userListMarginTotal / visibleUserFeedbackList.length) + singleUserRowHeight);

                const user = visibleUserFeedbackList[rowIndex];

                if (expandedUsers[user.listKey]) {
                    calculatedHeight += ((user.products.length * singleProductRowHeight) + productListMarginTotal);
                    for (let prui = 0; prui < user.products.length; prui++) {
                        const userProduct = user.products[prui];

                        if (expandedProducts[userProduct.listKey]) {
                            calculatedHeight += ((userProduct.demoFeedback.length * singleDemoRowHeight) + demoListMarginTotal);
                        }
                    }
                }
                break;
        }

        return calculatedHeight;
    }

    const buildDemoedProductMoreInfoListItem = (productDemoed: DemoFeedbackProductInterface) => {
        return (<Grid container item xs={12} spacing={2} style={{ borderTop: "1px solid", margin: 16, backgroundColor: "whitesmoke" }} key={"more-info-modal-product-" + productDemoed.id}>
            <Grid item xs={12}>
                <Link to={"/products/" + productDemoed.product.id} target="_blank" rel="noopener noreferrer" style={{ borderBottomColor: "transparent" }}>
                    <h4 style={{ margin: 0, marginTop: 4 }}>{productDemoed.product.name}<i className="fas fa-eye blue" style={{ paddingLeft: 6 }}></i></h4>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <strong>
                    {(productDemoed.feedbackOnFeatures || productDemoed.feedbackOnPrice || productDemoed.feedbackOnFunctionality) ? "Complaints on:" : "No Complaints"}
                </strong>
                {(productDemoed.feedbackOnFeatures || productDemoed.feedbackOnPrice || productDemoed.feedbackOnFunctionality) ?
                    ((productDemoed.feedbackOnFeatures ? " Features," : "") +
                        (productDemoed.feedbackOnPrice ? " Price," : "") +
                        (productDemoed.feedbackOnFunctionality ? " Functionality," : "")).slice(0, -1)
                    : ""}
            </Grid>
            <Grid item xs={12}>
                <strong>Notes:</strong> {productDemoed.feedbackNotes}
            </Grid>
        </Grid>);
    }

    const renderMoreInfoModal = () => {
        if (!selectedFeedback) return <></>;

        let productElementsList = [];
        for (let dpi = 0; dpi < selectedFeedback.productsDemoed.length; dpi++) {
            const productDemoed = selectedFeedback.productsDemoed[dpi];
            productElementsList.push(buildDemoedProductMoreInfoListItem(productDemoed));
        }

        return (<ModalBasic
            open={showMoreInfoModal}
            close={() => setShowMoreInforModal(false)}
            style={{ borderRadius: 8 }}
        >
            <div id="demo-feedback-more-info-modal" className="py-5" style={{ maxWidth: 800, padding: 32, fontSize: 16, backgroundColor: "whitesmoke", borderRadius: 8 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <h2>Reseller Interaction</h2>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px" }}
                            elevation={0}
                            expanded={modalAccordionSectionSelected == "who"}
                            onChange={(e, shouldExpand) => {
                                if (shouldExpand) {
                                    setModalAccordionSectionSelected("who");
                                } else {
                                    setModalAccordionSectionSelected("");
                                }
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">
                                <h3>Who</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}><strong>User: </strong>{selectedFeedback.createdBy.name}</Grid>
                                    <Grid item xs={12}><strong>Rep: </strong>{selectedFeedback.rep.name}</Grid>
                                    <Grid item xs={12}><strong>Target: </strong>{
                                        selectedFeedback.demoForExistingReseller ? selectedFeedback.targetReseller.name : ((selectedFeedback.potentialResellerName ? selectedFeedback.potentialResellerName : "") + " (a potential reseller)")}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px" }}
                            elevation={0}
                            expanded={modalAccordionSectionSelected == "what"}
                            onChange={(e, shouldExpand) => {
                                if (shouldExpand) {
                                    setModalAccordionSectionSelected("what");
                                } else {
                                    setModalAccordionSectionSelected("");
                                }
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">

                                <h3>What</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>

                                <Grid item xs={12} container>
                                    {selectedFeedback.formType == "demo-feedback" ? productElementsList : selectedFeedback.notes}
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    </Grid>


                    <Grid item xs={12}>
                        <Accordion
                            style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px" }}
                            elevation={0}
                            expanded={modalAccordionSectionSelected == "when"}
                            onChange={(e, shouldExpand) => {
                                if (shouldExpand) {
                                    setModalAccordionSectionSelected("when");
                                } else {
                                    setModalAccordionSectionSelected("");
                                }
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">
                                <h3>When</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <strong>Date of Demo: </strong>{selectedFeedback.dateOfDemo.toLocaleDateString()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong>Date of Report: </strong>{selectedFeedback.createdAt.toLocaleDateString()}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>


                    <Grid item xs={12}>
                        <Accordion
                            style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px" }}
                            elevation={0}
                            expanded={modalAccordionSectionSelected == "why"}
                            onChange={(e, shouldExpand) => {
                                if (shouldExpand) {
                                    setModalAccordionSectionSelected("why");
                                } else {
                                    setModalAccordionSectionSelected("");
                                }
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">
                                <h3>Why</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <strong>{"Product" + (selectedFeedback.productsDemoed.length > 1 ? "s" : "") + " considered for: "}</strong>
                                        {selectedFeedback.projectPurpose}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong>Details: </strong>
                                        {selectedFeedback.opportunityNotes}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 20px 30px 0px" }}
                            elevation={0}
                            expanded={modalAccordionSectionSelected == "result"}
                            onChange={(e, shouldExpand) => {
                                if (shouldExpand) {
                                    setModalAccordionSectionSelected("result");
                                } else {
                                    setModalAccordionSectionSelected("");
                                }
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none", alignItems: "center" }} className="line-item">
                                <h3>Result</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", borderTop: "1px solid lightgrey", paddingTop: 24 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <strong>Likelihood to close: </strong>
                                        {selectedFeedback.likelihoodToCloseDeal}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <strong>Help request: </strong>
                                        {selectedFeedback.howCanWeHelp}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            {internalUser && <Button
                                onClick={() => deleteFeedback(selectedFeedback)}
                                variant={"contained"}
                                style={{ width: "100%", backgroundColor: "red", color: "white" }}
                            >
                                Delete
                            </Button>}
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={() => setShowMoreInforModal(false)}
                                variant={"outlined"}
                                color={"primary"}
                                style={{ width: "100%" }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </ModalBasic>);
    }

    const renderResults = () => {
        if (loading) {
            return <Loading height="100vh" title={"Loading Products"} position={"center"} />;
        } else {
            return <WindowScroller ref={windowScrollerRef}>
                {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                    return (<Grid container className="results">
                        <List
                            ref={virtualizedListRef}
                            autoHeight
                            width={width}
                            height={height}
                            rowHeight={(rowIndex) => calculateRowHeight((currentView == "products" ? "products" : (internalUser ? "reps" : "users")), rowIndex.index)}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            scrollTop={scrollTop}
                            rowRenderer={(itemProps) => renderRow(itemProps.index, itemProps.key, itemProps.style)}
                            noRowsRenderer={renderPlaceholder}
                            rowCount={(currentView == "products" ? visibleProductFeedbackList : (internalUser ? visibleRepFeedbackList : visibleUserFeedbackList)).length}
                            containerStyle={{ backgroundColor: "transparent", paddingTop: 10 }}
                            overscanRowCount={3}
                        />
                    </Grid>)
                }}
            </WindowScroller>;
        }
    };

    return (
        <div>
            {renderMoreInfoModal()}
            <Grid container spacing={4} justifyContent={"center"}>
                {buildFilters()}
                {renderResults()}
            </Grid>
        </div>
    );
};

export default DemoFeedbackList;
