import React from "react";
import { UserContext } from "context/User";
import IconBubble from "components/Account/Wizard/IconBubble";
import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import logo from "assets/images/logo.svg";
import Greeting from "components/utils/Greeting";
import Upload, { UploadType } from "components/utils/Upload";
import { makeAxiosCall } from "utils";
import ResellerInfo from "components/Account/Wizard/ResellerInfo";
import SuccessAni from "components/utils/SuccessAni";
import { states } from "interfaces/State";
import "scss/components/Account/Wizard/Wizard.scss";
import Loading from "components/utils/Loading";

interface WizardProps {
    hideNav?: boolean,
}

const Wizard = ({ hideNav = false }: WizardProps) => {
    const { currentUser, signOut, fetchCurrentUser } = React.useContext(UserContext);
    const [showResellerInfoForm, setShowResellerInfoForm] = React.useState<boolean>(false);
    const [showResellerAgreementForm, setShowResellerAgreementForm] = React.useState<boolean>(false);
    const [showUploadForm, setShowUploadForm] = React.useState<boolean>(false);
    const [stepsComplete, setStepsComplete] = React.useState<boolean>(false);
    const [loadingDocusign, setLoadingDocusign] = React.useState<boolean>(false);

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const resellerAgreementQuery = urlParams.get("ra");
        const resellerAgreementCompleteQuery = urlParams.get("event");
        // const resellerInfoQuery = urlParams.get("ri");

        if (currentUser && currentUser.company.id) {
            if (resellerAgreementQuery === "269394jdiepow494" || resellerAgreementCompleteQuery == "signing_complete") {
                // const hubspotData = {
                //     companyId: currentUser.company.id,
                //     properties: {
                //         reseller_agreement_portal_: true,
                //     },
                // };
                setShowResellerAgreementForm(false);
                // updateHubspotCompany(hubspotData, userToken);

                if (!currentUser.company.approvalStatus.agreementComplete) {
                    makeAxiosCall(
                        "post",
                        `reseller-approval-status/${currentUser.company.id}`,
                        {
                            approvalId: currentUser.company.approvalStatus.id,
                            agreementComplete: true,
                            agreementDenialReason: ""
                        },
                        { headers: { "Content-Type": "application/json", } },
                    ).then(() => {
                        fetchCurrentUser();
                    });
                }
            }
        }
        fetchCurrentUser();

        if (
            currentUser.company.approvalStatus.agreementComplete &&
            currentUser.company.approvalStatus.certificateUploaded &&
            currentUser.company.approvalStatus.infoFormComplete
        ) {
            setStepsComplete(true);
        }

    }, []);


    React.useEffect(() => {
        if (
            currentUser.company.approvalStatus.agreementComplete &&
            currentUser.company.approvalStatus.certificateUploaded &&
            currentUser.company.approvalStatus.infoFormComplete
        ) {
            // TODO: CHECK IF WIZARD COMPLETE EMAIL SENT
            // IF NOT SEND I'M DONE EMAIL
            // MAKE SURE THERE ARE NO DENIALS STILL

            setStepsComplete(true);
        }

    }, [currentUser]);

    const createDocusign = async () => {
        setLoadingDocusign(true);
        if (
            currentUser.company &&
            currentUser.company.fishbowlCustomerId &&
            currentUser.company.approvalStatus.infoFormComplete &&
            currentUser.company.contacts.length > 0 &&
            currentUser.company.addresses.length > 0
        ) {
            const paymentTerms = await makeAxiosCall(
                "get",
                `paymentterms/${currentUser.company.fishbowlCustomerId}`,
                null,
                { headers: { "Content-Type": "application/json", } },
            );

            let primaryContact = currentUser.company.contacts.find((contact) => {
                return contact.contactTypes.some((type) => type.name == "Primary");
            });

            if (!primaryContact) {
                primaryContact = currentUser.company.contacts[0];
            }

            let primaryAddress = currentUser.company.addresses.find((address) => {
                return address.addressTypes.some((type) => type.name == "Primary");
            });

            if (!primaryAddress) {
                primaryAddress = currentUser.company.addresses[0];
            }

            let firstName = currentUser.firstName;
            if (!firstName || firstName.trim().length < 2) firstName = primaryContact.firstName;

            let lastName = currentUser.lastName;
            if (!lastName || lastName.trim().length < 2) lastName = primaryContact.lastName;

            let state = primaryAddress.state;

            if (state.length == 2) {
                const stateObject = states.find((st) => st.code == state);

                if (stateObject) state = stateObject.name;
            }

            let companyName = currentUser.company.resellerInfo.legalEntityName;
            if(currentUser.company.resellerInfo.dbas != null && currentUser.company.resellerInfo.dbas.length > 0) {
                companyName += " DBA " + currentUser.company.resellerInfo.dbas;
            }

            let docusignData = {
                resellerEmail: currentUser.email,
                resellerName: firstName + " " + lastName,
                currentUserId: currentUser.uid,
                companyId: currentUser.company.id,
                companyName: companyName,
                resellerTitle: primaryContact.title,
                businessType: currentUser.company.resellerInfo.businessType,
                city: primaryAddress.city,
                state: state,
                address: (primaryAddress.streetAddress + (primaryAddress.streetAddressTwo ? (", " + primaryAddress.streetAddressTwo) : "")),
                zip: primaryAddress.zip,
                country: primaryAddress.country,
                paymentTerms: paymentTerms.data
            }


            let response = await makeAxiosCall(
                "post",
                `docusign/create-agreement`,
                docusignData,
                { headers: { "Content-Type": "application/json", } },
            );

            setLoadingDocusign(false);


            if (response) {
                console.log(response.data);
                return response.data;
            } return false;
        }
    };

    const buildCompany = () => {
        return (
            <>
                <SuccessAni title="Thank You" />

                <p style={{ fontSize: 16, paddingBottom: 10 }}>
                    We&apos;re currently reviewing your account and will email you with further instructions.
                </p>
                <Button
                    type="submit"
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    className="btn normal"
                    onClick={() => {
                        window.location.href = "https://blizzardpro.com";
                    }}
                >
                    Go to blizzardpro.com
                </Button>
            </>
        );
    };

    const buildResellerCertificateForm = () => {
        return (
            <div style={{ maxWidth: 600, margin: "auto", textAlign: "center" }}>
                <Button 
                    style={{ float: "left" }} 
                    onClick={() => {
                        setShowUploadForm(false);
                        fetchCurrentUser();
                    }}
                >
                    Back
                </Button>
                <Upload
                    description="Please complete and submit the form below:"
                    maxFileSize={10000000}
                    uploadType={UploadType.cert}
                    onFinished={() => {
                        setShowUploadForm(false);
                        fetchCurrentUser();
                    }}
                    successMessage={
                        "Your resale certificate has been submitted and is pending Blizzard approval."
                    }
                />
            </div>
        );
    };

    const buildResellerAgreementForm = () => {
        return (
            <div style={{
                width: "100%",
                height: 400,
                maxWidth: 800,
                margin: "0 auto",
                marginTop: "20%",
            }}>
                <h2>{ loadingDocusign ? "Building Reseller Agreement Document..." : "You're leaving the portal."}</h2>
                {
                    loadingDocusign ? <Loading height="40vh" title="" position="relative" /> : 
                        <p style={{ fontSize: 16, paddingBottom: 10 }}>
                            We are redirecting you to the Docusign system in order for you to
                            securely review and sign your Reseller Agreement. You will be
                            returned to the portal once you&apos;ve completed the e-signature process.
                        </p> 
                }
                
                <div className="btn-group">
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="secondary"
                        className="btn"
                        disabled={loadingDocusign}
                        onClick={() => {
                            setShowResellerAgreementForm(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        type="submit"
                        style={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        className="btn"
                        disabled={loadingDocusign}
                        onClick={() => {
                            createDocusign().then((docData) => {
                                if(docData && docData.redirectUrl)
                                window.location.href = docData.redirectUrl;
                            });
                        }}
                    >
                        Let&apos;s Go
                    </Button>
                </div>
            </div>
        );
    };

    const buildDocumentsUnderReview = () => {
        return (

            <div style={{ marginTop: 20 }}>
                <SuccessAni title="Thank You" />

                <p style={{ fontSize: 16, paddingBottom: 10 }}>
                    We&apos;re currently reviewing the information and documents you&apos;ve provided, and will contact you when finished.
                </p>
                <h3>
                    Typical Wait Time:{" "}
                    <span style={{ color: "#282bf6" }}>1 business day</span>
                </h3>
            </div>
        );
    };

    const buildInfoFormButtonText = () => {
        if (currentUser.company.approvalStatus.infoFormDenialReason) return "Update";
        else if (currentUser.company.approvalStatus.infoFormApproved) return "Approved";
        else if (currentUser.company.approvalStatus.infoFormComplete) return "Submitted";
        else return "Start";
    };


    const buildCertButtonText = () => {
        if (currentUser.company.approvalStatus.certificateDenialReason) return "Update";
        else if (currentUser.company.approvalStatus.certificateApproved) return "Approved";
        else if (currentUser.company.approvalStatus.certificateUploaded) return "Upload More";
        else return "Upload";
    };

    const buildAgreementButtonText = () => {
        if (currentUser.company.approvalStatus.agreementDenialReason) return "Update";
        else if (currentUser.company.approvalStatus.agreementApproved) return "Approved";
        else if (currentUser.company.approvalStatus.agreementComplete) return "Submitted";
        else return "Review & Sign";
    };

    const buildNavigationButtons = () => {
        return (
            <Grid container spacing={4} className="wizard-navigation-button-container">
                {/* RESELLER INFO FORM */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} className="wizard-navigation-card">
                        <IconBubble
                            complete={currentUser.company.approvalStatus.infoFormComplete}
                            denied={!!currentUser.company.approvalStatus.infoFormDenialReason}
                            icon="fad fa-address-card"
                        />
                        <h3>Reseller Information Form</h3>
                        <p>
                            {currentUser.company.approvalStatus.infoFormDenialReason ? currentUser.company.approvalStatus.infoFormDenialReason : "This form lets us know the contact and company information of your business."}
                        </p>
                        <Button
                            onClick={() => setShowResellerInfoForm(true)}
                            variant="contained"
                            color="primary"
                            style={{ width: "100%", position: "relative", top: -18 }}
                            className={currentUser.company.approvalStatus.infoFormDenialReason ? "btn red" : (currentUser.company.approvalStatus.infoFormComplete ? "btn green" : "btn")}
                        >
                            {buildInfoFormButtonText()}
                        </Button>
                    </Paper>
                </Grid>

                {/* RESALE CERT FORM */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} className="wizard-navigation-card">
                        <IconBubble
                            complete={currentUser.company.approvalStatus.certificateUploaded}
                            denied={!!currentUser.company.approvalStatus.certificateDenialReason}
                            icon="fad fa-file-certificate"
                        />
                        <h3>Upload Resale Certificate</h3>
                        <p>
                            {currentUser.company.approvalStatus.certificateDenialReason ? currentUser.company.approvalStatus.certificateDenialReason : "Submit your resale certificate for approval."}
                        </p>
                        <Button
                            onClick={() => setShowUploadForm(true)}
                            variant="contained"
                            color="primary"
                            style={{ width: "100%", marginBottom: 20 }}
                            className={currentUser.company.approvalStatus.certificateDenialReason ? "btn red" : (currentUser.company.approvalStatus.certificateUploaded ? "btn green" : "btn")}
                            disabled={currentUser.company.approvalStatus.certificateApproved}
                        >
                            {buildCertButtonText()}
                        </Button>
                    </Paper>
                </Grid>

                {/* RESELLER AGREEMENT FORM  */}
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} className="wizard-navigation-card">
                        <IconBubble
                            complete={currentUser.company.approvalStatus.agreementComplete}
                            denied={!!currentUser.company.approvalStatus.agreementDenialReason}
                            icon="fad fa-file-alt"
                        />
                        <h3>Sign Reseller Agreement</h3>
                        <p>
                            {currentUser.company.approvalStatus.agreementDenialReason ? currentUser.company.approvalStatus.agreementDenialReason : "Review and sign our reseller agreement."}
                        </p>
                        <Tooltip
                            title={!currentUser.company.approvalStatus.infoFormComplete ? "Please complete the Reseller Information Form first." : ""}
                        >
                            <span>
                                <Button
                                    onClick={() => setShowResellerAgreementForm(true)}
                                    style={{ width: "100%", marginBottom: 20 }}
                                    variant="contained"
                                    color="primary"
                                    disabled={currentUser.company.approvalStatus.agreementComplete || currentUser.company.approvalStatus.agreementApproved || (!currentUser.company.approvalStatus.infoFormComplete && !currentUser.company.approvalStatus.infoFormApproved)}
                                    className={currentUser.company.approvalStatus.agreementDenialReason ? "btn red" : (currentUser.company.approvalStatus.agreementComplete ? "btn green" : "btn")}
                                >
                                    {buildAgreementButtonText()}
                                </Button>
                            </span>
                        </Tooltip>
                    </Paper>
                </Grid>
            </Grid>
        );
    };

    const buildBodyOfTheWizard = () => {
        if (!currentUser.company) {
            // You are not assigned to a company
            return buildCompany();
        } else if (showResellerInfoForm) {
            // Show Main Info Form
            return <ResellerInfo closeForm={() => {
                fetchCurrentUser();
                setShowResellerInfoForm(false);
            }} />;
        } else if (showUploadForm) {
            // Show Cert Form
            return buildResellerCertificateForm();
        } else if (showResellerAgreementForm) {

            // Show Reseller Agreement Form
            return buildResellerAgreementForm();
        } else {
            return <></>;
        }
    };


    return (
        <Grid container className="wizard">
            <img className="logo-top" src={logo} alt="Blizzard" />

            {!hideNav && (
                <div className="top-floating-nav">

                    <div
                        className="back-crumb"
                        onClick={() => {
                            fetchCurrentUser();
                        }}
                    >
                        <i className="fad fa-reload"></i> Refresh
                    </div>
                    <div
                        className="back-crumb"
                        onClick={() => {
                            signOut();
                        }}
                    >
                        <i className="fad fa-sign-out"></i> Log Out
                    </div>
                </div>
            )}

            <Grid item xs={12}>
                {
                    buildBodyOfTheWizard()
                }
            </Grid>

            {
                showUploadForm || showResellerAgreementForm || showResellerInfoForm ? <></> :
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className="intro">
                                    <div className="top-intro">
                                        <Greeting />
                                        {!stepsComplete ? <h2>Please complete the following.</h2> : buildDocumentsUnderReview()}
                                        {!stepsComplete && currentUser?.company?.approvalStatus?.expirationDate && <h3>{currentUser?.company?.approvalStatus?.expirationReason}</h3>}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {buildNavigationButtons()}
                            </Grid>
                        </Grid>
                    </Grid>
            }



        </Grid>
    );
};

export default Wizard;
