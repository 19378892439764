import React, { useContext } from "react";
import { Button, FormControlLabel, Grid, List, ListItem, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Snackbar, TextareaAutosize, TextField } from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import "scss/components/Account/Wizard/ResellerInfo.scss";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import { DemoFeedbackInterface, DemoFeedbackProductInterface, DemoFeedbackProjectPurposeOptions, emptyDemoFeedbackForm, emptyDemoFeedbackFormProduct, LikelihoodToCloseDealOptions } from "interfaces/DemoFeedback";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { BasicCompanyInterface } from "interfaces/Company";
import { Link } from "react-router-dom";
import { ProductContext } from "context/Product";
import { BaseProductListingInterface, getSortOrder, ProductInterface } from "interfaces/Product";
import { Add, Save } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { DefaultPDProgressChecklist, DefaultPDProgressChecklistMassRework, emptyPDProjectInterface, HardCodedFactoryList, PDProductBaubleInterface, PDProjectInterface, PDProjectTypes } from "interfaces/ProductDevelopment";
import PDProjectSampleList from "./FormComponents/SampleList";

interface NewPDProjectFormErrorInterface {
    name: boolean,
    factory: boolean,
    projectType: boolean,
    sample: boolean,
    price: boolean,
    product: boolean
}

const NewPDProjectFormErrorInterfaceDefaults = {
    name: false,
    factory: false,
    projectType: false,
    sample: false,
    price: false,
    product: false
};

const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };

const NewPDProjectForm = () => {
    const [newProject, setNewProjectData] = React.useState<PDProjectInterface>(emptyPDProjectInterface());
    const [formErrors, setFormErrors] = React.useState<NewPDProjectFormErrorInterface>(NewPDProjectFormErrorInterfaceDefaults);
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [saving, setSaving] = React.useState(false);


    const { products } = React.useContext(ProductContext);


    const navigate = useNavigate();

    React.useEffect(() => {
        // _fetchFactoryList();
    }, []);

    // const _fetchResellerList = () => {
    //     makeAxiosCall(
    //         "get",
    //         "companies/simple"
    //     ).then(res => {
    //         const companyList: BasicCompanyInterface[] = [];
    //         for (let ci = 0; ci < res.data.length; ci++) {
    //             const company = res.data[ci];
    //             companyList.push({ id: company.id, name: company.name });
    //         }
    //         setResellerList(companyList);
    //     });
    // }

    const _buildName = () => {
        return (
            <Grid item xs={12} md={8}>
                <h3>Project name</h3>
                
                <TextField
                    aria-label="project-name"
                    label={"Project Name"}
                    variant="outlined"
                    helperText={"Potential real product name is prefered, but a sample name will do in a pinch."}
                    fullWidth={true}
                    placeholder=""
                    error={formErrors.name}
                    value={newProject.name}
                    onChange={(e) => {
                        setNewProjectData({...newProject, name: e.target.value})
                    }}
                />
            </Grid>
        );
    }

    const _showErrorText = (errorText: string) => {

        setSnackbar({
            isOpen: true,
            message: errorText,
            severity: "error"
        });
    }


    const _buildSampleList = () => {
        return <PDProjectSampleList 
            project={newProject}
            onUpdate={(newSampleList: PDProductBaubleInterface[]) => setNewProjectData({...newProject, baubles: newSampleList})}
            newProjectForm={true}
            showErrorText={_showErrorText}
            saveBauble={null}
        />
    }


    const _buildFactoryDropdown = () => {
        return (
            <Grid item xs={12} md={8}>
                <h3>Factory</h3>
                <Autocomplete
                    value={newProject.factory}
                    fullWidth={true}
                    options={HardCodedFactoryList}
                    freeSolo={true}
                    onChange={(event, newValue) => {
                        setNewProjectData({...newProject, factory: newValue});
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Select the factory"}
                            helperText={"If the factory isn't listed, select 'other' for now, and tell Devon."}
                            variant="outlined"
                            fullWidth
                            error={formErrors.factory}
                        />
                    )}
                />
            </Grid>
        );
    }

    const _buildProductDropdown = () => {
        return (
            <Grid item xs={12} md={12}>
                <h3>Product</h3>
                <Autocomplete
                    value={newProject.product?.name}
                    fullWidth={true}
                    options={products.map((p) => p.name)}
                    freeSolo={true}
                    onChange={(event, newValue) => {
                        setNewProjectData({...newProject, product: products.find((p) => p.name === newValue)});
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Select the product"}
                            variant="outlined"
                            fullWidth
                            error={formErrors.product}
                        />
                    )}
                />
            </Grid>
        );
    }

    const _buildType = () => {
        return (
            <Grid item xs={12} md={4}>
                <h3>Project Type</h3>
                <Select
                    key={"project-type"}
                    value={newProject.projectType.name}
                    onChange={(e: React.ChangeEvent<{ name?: string, value: string }>) => {
                        setNewProjectData({...newProject, projectType: PDProjectTypes.find((pt) => pt.name === e.target.value), checklists: e.target.value === "Mass Rework" ? [DefaultPDProgressChecklistMassRework] : [DefaultPDProgressChecklist]});
                    }}
                    input={<OutlinedInput />}
                    fullWidth={true}
                    error={formErrors.projectType}
                    style={{ width: "100%", textAlign: "left" }}
                >
                    {PDProjectTypes.map(
                        (pt) => {
                            return (
                                <MenuItem
                                    key={pt.id + "-dropdown-type-option"}
                                    value={pt.name}
                                >
                                    {pt.name}
                                </MenuItem>
                            );
                        }
                    )}
                </Select>
            </Grid>
        );
    }

    const _buildPriceField = () => {
        return (
            <Grid item xs={12} md={4}>
                <h3>Factory Price</h3>
                
                <TextField
                    aria-label="project-price"
                    label={"Price per unit"}
                    variant="outlined"
                    type={"number"}
                    helperText={"Price sets expectations when testing."}
                    fullWidth={true}
                    placeholder=""
                    value={newProject.price}
                    onChange={(e) => {
                        let newPrice = parseFloat(e.target.value);
                        setNewProjectData({...newProject, price: newPrice})
                    }}
                    onBlur={(e) => {
                        let newPrice = parseFloat(e.target.value);
                        if(!newPrice || newPrice < 0) {
                            setNewProjectData({...newProject, price: 0})
                        }
                    }}
                />
            </Grid>
        );
    }


    const _buildBonusFields = () => {
        return (
            <Grid item xs={12} container>
                <h3>Details & Notes</h3>
                <Grid item xs={12}>
                    <TextField
                        aria-label="project-description"
                        label={"Description"}
                        variant="outlined"
                        fullWidth={true}
                        multiline={true}
                        placeholder="Enter any project specifics here...,
                        e.g., how did we hear about this, is this a direct replacement to an existing product, ."
                        value={newProject.description}
                        onChange={(e) => {
                            setNewProjectData({...newProject, description: e.target.value})
                        }}
                    />
                </Grid>
            </Grid>
        );
    }


    const _buildTypeBasedFields = () => {
        let listItems = [];
        switch(newProject.projectType.id) {
            case 3:
                listItems.push(_buildProductDropdown());
                break;
            case 2:
                break;
            case 1:
            default:
                listItems.push(_buildFactoryDropdown());
                listItems.push(_buildPriceField());
                listItems.push(_buildSampleList());
                break;
        }
        return listItems;
    }


    const _verifyForm = (): boolean => {
        let newErrors = {...NewPDProjectFormErrorInterfaceDefaults};
        let issues = false;
        if(!newProject.name) {
            newErrors.name = true;
            issues = true;
        }

        if(newProject.projectType.name == "New Product") {
            if(!newProject.factory) {
                newErrors.factory = true;
                issues = true;
            }

            if(newProject.baubles.length > 0 && !newProject.baubles.every((b) => b.name && b.name.length)) {
                newErrors.sample = true;
                issues = true;
            } 
        } else if(newProject.projectType.name == "Mass Rework") {
            if(!newProject.product) {
                newErrors.product = true;
                issues = true;
            }
        }


        setFormErrors(newErrors);

        if(issues) {
            setSnackbar({
                isOpen: true,
                message: "Looks like you're missing something.",
                severity: "error",
            });
        }

        return !issues;
    }

    const _saveForm = () => {
        makeAxiosCall(
            "post",
            "pd/projects",
            newProject
        ).then(() => {
            // form saved  now what
            setSaving(false);
            navigate(-1);

        }).catch((err) => {
            setSaving(false);
            console.log(err);
        });
    }

    const _buildSubmitButton = () => {
        return (
            <Grid item xs={12} md={4}>
                <Button
                    fullWidth={true}
                    disabled={saving}
                    onClick={() => {
                        setSaving(true);
                        if(_verifyForm()) {
                            _saveForm();
                        } else {
                            setSaving(false);
                        }
                    }}
                    variant="contained"
                    color="primary"
                    className="btn"
                >
                    <Save style={{marginRight: 8}} /> Create Project
                </Button>
            </Grid>
        );
    }

    return (
        <div className="view">
            <Grid container spacing={4} justifyContent={"center"}>
                <Grid item xs={12}>
                    <h1 style={{width: "100%"}}>New PD Project</h1>
                </Grid>
                {_buildName()}
                {_buildType()}
                {_buildTypeBasedFields()}
                {_buildBonusFields()}
                {_buildSubmitButton()}
            </Grid>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={30000}
                onClose={() =>  setSnackbar({ ...snackbar, isOpen: false })}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default NewPDProjectForm;
