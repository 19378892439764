export interface ProductInterface {
    id: number,
    name: string,
    description: string,
    fishbowlId: number,
    fishbowlName?: string,
    shopifyId?: number,
    sku: string,
    upc?: number,
    imageUrl?: string,
    websiteUrl?: string,
    productType?: string,
    category?: string,
    productTypeSortOrder: number,
    downloads?: Array<string>,
    price: number,
    mapPrice?: number,
    msrpPrice?: number,
    inventory: number,
    bstock: number,
    inventoryAllocated?: number,
    inventoryNotAvailable?: number,
    inventoryOnOrder?: number,
    warranty?: string,
    warrantyTypeId?: number, // only used in saving right now
    dimensions?: ProductDimensionsInterface,
    customFields?: ProductCustomFieldInterface[],
    baseProduct?: BaseProductInterface,
    variantLabel?: string,
    webflowFields: ProductWebflowFieldInterface[],
    collections: ProductWebflowCollectionItemInterface[],
    media?: ProductMediaInterface[],
    status: ProductStatusInterface,
    featured: boolean,
    productLaunchDate?: Date,
    vendor?: ProductVendorInterface,
    lastOrderCost?: number,
}

export const productFromHasuraObject = (data): ProductInterface => {
    let collections: ProductWebflowCollectionItemInterface[] = [];
    if(data.collections) {
        for (let ci = 0; ci < data.collections.length; ci++) {
            const c = data.collections[ci];

            collections.push({
                itemId: c.item_id ?? c.itemId,
                collection: {
                    id: c.collection.id,
                    name: c.collection.name,
                    slug: c.collection.slug,
                    site: c.collection.domain
                }
            });
            
        }
    }


    return {
        id: data.id,
        name: data.name,
        description: data.description,
        fishbowlId: data.fishbowl_id,
        fishbowlName: data.fishbowl_name,
        shopifyId: data.shopify_id,
        sku: data.sku,
        upc: data.upc,
        imageUrl: data.image_url,
        websiteUrl: data.website_url,
        productType: data.product_type,
        productTypeSortOrder: getSortOrder(data.product_type),
        category: data.category ?? "Unknown",
        downloads: data.downloads,
        price: data.price,
        mapPrice: data.price_map,
        msrpPrice: data.price_msrp,
        inventory: data.inventory,
        bstock: data.inventory_bstock,
        inventoryAllocated: data.inventory_allocated,
        inventoryNotAvailable: data.inventory_not_available,
        inventoryOnOrder: data.inventory_on_order,
        warranty: data.warranty?.name,
        dimensions: data.dimensions ? buildProductDimensions(data.dimensions) : null,
        customFields: data.custom_fields ? buildCustomFieldList(data.custom_fields) : null,
        baseProduct: data.base_product ? buildBaseProduct(data.base_product) : null,
        variantLabel: data.variant_label,
        collections: collections,
        webflowFields: data.webflow_fields ? buildWebflowFieldList(data.webflow_fields) : [],
        media: data.media ? buildProductMediaList(data.media) : null,
        status: {
            visiblePortal: data.visible_portal ?? false,
            visiblePublic: data.visible_public ?? false,
            discontinued: data.discontinued ?? false
        },
        featured: !!data.featured,
        productLaunchDate: data.product_launch_date ? new Date(data.product_launch_date) : new Date("1-1-1990")
    };
};

export const getSortOrder = (productType) => {
    let sortOrder: number = 15;

    switch (productType) {
        case "LED Fixture":
            sortOrder = 1;
            break;
        case "Video Wall Panel":
            sortOrder = 2;
            break;
        case "Lamp Fixture":
            sortOrder = 3;
            break;
        case "NovaStar":
            sortOrder = 4;
            break;
        case "Lighting Controller":
            sortOrder = 5;
            break;
        case "Atmospheric":
            sortOrder = 6;
            break;
        case "Laser Fixture":
            sortOrder = 7;
            break;
        case "Wireless DMX & DMX Distribution":
            sortOrder = 8;
            break;
        case "Komply":
            sortOrder = 9;
            break;
        case "Cable":
            sortOrder = 10;
            break;
        case "Video Wall Component":
            sortOrder = 11;
            break;
        case "Fixture Accessory":
            sortOrder = 12;
            break;
        case "Consumable":
            sortOrder = 13;
            break;
        case "Computer Case":
            sortOrder = 14;
            break;
    
        default:
            sortOrder = 15;
            break;
    }

    return sortOrder;
};

export const buildProductsList = (rawProductList) => {
    const products: ProductInterface[] = [];
    for (let rali = 0; rali < rawProductList.length; rali++) {
        const rawProduct = rawProductList[rali];
        products.push(productFromHasuraObject(rawProduct));
    }

    return products;
};

export interface ProductDimensionsInterface {
    id?: number,
    length?: number,
    width?: number,
    height?: number,
    weight?: number,
    assembledLength?: number,
    assembledWidth?: number,
    assembledHeight?: number,
    assembledWeight?: number,
    masterPackLength?: number,
    masterPackWeight?: number,
    masterPackHeight?: number,
    masterPackWidth?: number,
    masterPackQuantity?: number,
}

export const buildProductDimensions = (rawData) => {
    return {
        id: rawData.id,
        length: rawData.length,
        width: rawData.width,
        height: rawData.height,
        weight: rawData.weight,
        assembledLength: rawData.assembled_length,
        assembledWidth: rawData.assembled_width,
        assembledHeight: rawData.assembled_height,
        assembledWeight: rawData.assembled_weight,
        masterPackLength: rawData.master_pack_length,
        masterPackWeight: rawData.master_pack_weight,
        masterPackHeight: rawData.master_pack_height,
        masterPackWidth: rawData.master_pack_width,
        masterPackQuantity: rawData.master_pack_quantity
    };
};

export interface ProductCustomFieldInterface {
    id?: number,
    fieldId: number,
    name: string,
    fieldType: string,
    value?: any,
    fishbowlId?: number,
    shopifyKey?: string,
    shopifyNamespace?: string,
    shopifyType?: string,
}

export const buildCustomField = (rawData): ProductCustomFieldInterface => {
    return {
        id: rawData.id,
        fieldId: rawData.field.id,
        name: rawData.field.name,
        fieldType: rawData.field.field_type,
        value: rawData.value,
        fishbowlId: rawData.field.fishbowl_id,
        shopifyKey: rawData.field.shopify_field_key,
        shopifyNamespace: rawData.field.shopify_field_namespace,
    };
};

export const buildCustomFieldList = (rawData): ProductCustomFieldInterface[] => {
    return rawData.map((field) => buildCustomField(field));
};


export interface ProductWebflowFieldInterface {
    customFieldId: number,
    name: string,
    fieldType: string,
    slug: string,
    value?: any
    customFieldDataId?: number,
    validateCollectionIds: string[]
}

export const buildWebflowField = (rawData): ProductWebflowFieldInterface => {
    let value;
    if(rawData.field_type === "Checkbox") {
        value = (rawData.value === true || rawData.value === "true" || rawData.value === "True" || rawData.value === 1);
    } else {
        value = rawData.value;
    }

    return {
        customFieldId: rawData.custom_field_id,
        name: rawData.name,
        fieldType: rawData.field_type,
        slug: rawData.slug,
        value: value,
        customFieldDataId: rawData.custom_field_data_id,
        validateCollectionIds: rawData.webflow_validation_collection_ids
    };
};

export const buildWebflowFieldList = (rawData): ProductWebflowFieldInterface[] => {
    return rawData.map((field) => buildWebflowField(field));
};

export interface BaseProductInterface {
    id: number,
    name: string,
    sku: string,
    imageUrl: string,
    websiteUrl: string,
    featuredChildId?: number
}

export const buildBaseProduct = (rawData): BaseProductInterface => {
    return {
        id: rawData.id,
        name: rawData.name,
        sku: rawData.sku,
        imageUrl: rawData.image_url,
        websiteUrl: rawData.website_url,
        featuredChildId: rawData.featured_child_id
    };
};

export const buildBaseProductList = (rawData): BaseProductInterface[] => {
    return rawData.map((product) => buildBaseProduct(product));
};

export interface ProductWebflowCollectionItemInterface {
    itemId: string,
    collection: ProductWebflowCollectionInterface
}

export interface ProductWebflowCollectionInterface {
    id: string,
    name: string,
    slug: string,
    site: string
}

export interface ProductMediaInterface {
    id: number,
    name: string,
    url: string,
    type: ProductMediaTypeInterface,
    public: boolean,
    order?: number
}

export const buildProductMedia = (rawData): ProductMediaInterface => {
    return {
        id: rawData.id,
        name: rawData.name,
        url: rawData.url,
        type: {
            id: rawData.media_type.id,
            name: rawData.media_type.name,
        },
        public: rawData.public,
        order: rawData.order != null && rawData.order != undefined ? rawData.order : 99
    };
};

export const buildProductMediaList = (rawData): ProductMediaInterface[] => {
    return rawData.map((media) => buildProductMedia(media));
};

export interface ProductMediaTypeInterface {
    id: number,
    name: string
}

export const WebflowCollections = [
    {
        id: "6454060ee94db518a1803d42",
        name: "Pro Products",
        domain: "https://www.blizzardpro.com/",
        slug: "products"
    },
    {
        id: "64340ca3d763b7df13ec841a",
        name: "NovaStar",
        domain: "https://irispro.co/",
        slug: "novastar"
    },
    {
        id: "64340ca3d763b7ac51ec8438",
        name: "IRiS Components",
        domain: "https://irispro.co/",
        slug: "iris-video-wall-components"
    },
    {
        id: "64340ca3d763b73e0bec83fd",
        name: "IRiS Panels",
        domain: "https://irispro.co/",
        slug: "ledvideo"
    },
    {
        id: "64554329251d885e309e430e",
        name: "Mixtape",
        domain: "https://blizzardmixtape.com",
        slug: "products"
    }
]

export const ProductCategories = [
    "Wash Lights",
    "Battery Powered",
    "Battens & Bars",
    "Blinders & Strobes",
    "Pixel Mapping & FX",
    "PixelHue",
    "Moving Heads",
    "Ellipsoidals",
    "UV & Atmospherics",
    "LED Video",
    "LED Ribbon",
    "Misc",
    "Accessories & Cases",
    "Lasers",
    "Wireless DMX + Control",
    "NovaStar"
]

export interface ProductStatusInterface {
    visiblePortal: boolean,
    visiblePublic: boolean,
    discontinued: boolean
}


export interface BaseProductListingInterface {
    id: number,
    name: string,
    fishbowlName?: string,
    sku: string,
    imageUrl: string,
    websiteUrl: string,
    status: ProductStatusInterface,
    featured: boolean,
    products: ProductInterface[],
    productTypeSortOrder: number,
    productLaunchDate?: Date
}

export interface BasicProductInterface {
    id: number,
    name: string,
    imageUrl?: string,
}


export const buildBasicProductInterface = (rawData): BasicProductInterface => {
    return {
        id: rawData.id,
        name: rawData.name,
        imageUrl: rawData.image_url,
    };
};

export interface ProductVendorInterface {
    id: number,
    name: string,
    fishbowlId?: number,
}

export const buildProductVendorInterface = (rawData): ProductVendorInterface => {
    return {
        id: rawData.id,
        name: rawData.name,
        fishbowlId: rawData.fishbowl_id,
    }
}
