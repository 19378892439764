import React from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { SideDrawerTab, ViewContext } from "context/View";
import { UserContext } from "context/User";
import ConnectionAlert from "components/utils/ConnectionAlert";

import { Link } from "react-router-dom";

interface SideDrawerProps {
    handleMobileEvent: (type: string) => void,
}

const SideDrawer = ({ handleMobileEvent }: SideDrawerProps) => {
    const { currentPanel, saveCurrentPanel } = React.useContext(ViewContext);
    const { currentUser, internalUser } = React.useContext(UserContext);

    return (
        <div>
            <Link to="/">
                <div
                    onClick={() => {
                        saveCurrentPanel(SideDrawerTab.home);
                        handleMobileEvent("mobile");
                    }}
                    className="badge"
                    style={{
                        position: "relative",
                        left: 0,
                        right: 0,
                        margin: "0 auto",
                    }}
                >
                    <ConnectionAlert />
                </div>
            </Link>

            <List>
                <Link style={{ marginTop: 40 }} className="sidenav-a" to="/">
                    <div
                        className={
                            currentPanel === SideDrawerTab.home
                                ? "sidenav-button active"
                                : "sidenav-button"
                        }
                        onClick={() => {
                            saveCurrentPanel(SideDrawerTab.home);
                            handleMobileEvent("mobile");
                        }}
                        key="Home"
                    >
                        <i className="far fa-home"></i>
                        <p>Home</p>
                    </div>
                </Link>

                <Link className="sidenav-a" to="/products">
                    <div
                        className={
                            currentPanel === SideDrawerTab.products
                                ? "sidenav-button active"
                                : "sidenav-button"
                        }
                        onClick={() => {
                            localStorage.removeItem("last-search");
                            saveCurrentPanel(SideDrawerTab.products);
                            handleMobileEvent("mobile");
                        }}
                        key="Create POs"
                    >
                        <i className="far fa-dolly-flatbed-alt"></i>
                        <p>Products</p>
                    </div>
                </Link>

                <Link className="sidenav-a" to="/order-history">
                    <div
                        className={
                            currentPanel === SideDrawerTab.orders
                                ? "sidenav-button active"
                                : "sidenav-button"
                        }
                        onClick={() => {
                            saveCurrentPanel(SideDrawerTab.orders);
                            handleMobileEvent("mobile");
                        }}
                        key="Order History"
                    >
                        <i className="far fa-receipt"></i>
                        <p>Orders</p>
                    </div>
                </Link>

                {/* <Link className="sidenav-a" to="/files">
          <div
            className={
              currentPanel === "files"
                ? "sidenav-button active"
                : "sidenav-button"
            }
            onClick={() => {
              saveCurrentPanel("files");
              handleMobileEvent("mobile");
            }}
            key="Files"
          >
            <i className="fas fa-folders"></i>
            <p>My Files</p>
          </div>
        </Link> */}

                <Link className="sidenav-a" to="/company">
                    <div
                        className={
                            currentPanel === SideDrawerTab.company
                                ? "sidenav-button active"
                                : "sidenav-button"
                        }
                        onClick={() => {
                            saveCurrentPanel(SideDrawerTab.company);
                            handleMobileEvent("mobile");
                        }}
                        key="Company"
                    >
                        <i className="far fa-building"></i>
                        <p>My Company</p>
                    </div>
                </Link>

                {currentUser &&
                    internalUser && (
                    <>
                        <Link className="sidenav-a" to="/admin">
                            <div
                                className={
                                    currentPanel === SideDrawerTab.admin
                                        ? "sidenav-button active"
                                        : "sidenav-button"
                                }
                                onClick={() => {
                                    saveCurrentPanel(SideDrawerTab.admin);
                                    handleMobileEvent("mobile");
                                }}
                                key="Admin"
                            >
                                <i className="far fa-snowflake"></i>
                                <p>Admin</p>
                            </div>
                        </Link>
                    </>
                )}

                {currentUser &&
                    (internalUser || (currentUser.company && currentUser.company.companyType == "Rep" && currentUser.accountLevel && currentUser.accountLevel.viewOrders)) && (
                    <>
                        <Link className="sidenav-a" to="/demos">
                            <div
                                className={
                                    currentPanel === SideDrawerTab.demos
                                        ? "sidenav-button active"
                                        : "sidenav-button"
                                }
                                onClick={() => {
                                    saveCurrentPanel(SideDrawerTab.demos);
                                    handleMobileEvent("mobile");
                                }}
                                key="Demos"
                            >
                                <i className="far fa-people-arrows"></i>
                                <p>Interactions</p>
                            </div>
                        </Link>
                    </>
                )}

                {currentUser &&
                    internalUser && (
                    <>
                        <Link className="sidenav-a" to="/reports">
                            <div
                                className={
                                    currentPanel === SideDrawerTab.reports
                                        ? "sidenav-button active"
                                        : "sidenav-button"
                                }
                                onClick={() => {
                                    saveCurrentPanel(SideDrawerTab.reports);
                                    handleMobileEvent("mobile");
                                }}
                                key="Reports"
                            >
                                <i className="fas fa-folders"></i>
                                <p>Reports</p>
                            </div>
                        </Link>
                    </>
                )}

                {currentUser &&
                    !internalUser && (
                    <>
                        <Link className="sidenav-a" to="/company?tab=documents">
                            <div
                                className={
                                    currentPanel === SideDrawerTab.documents
                                        ? "sidenav-button active"
                                        : "sidenav-button"
                                }
                                onClick={() => {
                                    saveCurrentPanel(SideDrawerTab.documents);
                                    handleMobileEvent("mobile");
                                }}
                                key="Documents"
                            >
                                <i className="far fa-file-invoice"></i>
                                <p>Documents</p>
                            </div>
                        </Link>
                    </>
                )}

                {currentUser &&
                    internalUser && (
                    <>
                        <Link className="sidenav-a" to="/pd">
                            <div
                                className={
                                    currentPanel === SideDrawerTab.pd
                                        ? "sidenav-button active"
                                        : "sidenav-button"
                                }
                                onClick={() => {
                                    saveCurrentPanel(SideDrawerTab.pd);
                                    handleMobileEvent("mobile");
                                }}
                                key="PD"
                            >
                                <i className="far fa-tornado"></i>
                                <p>PD</p>
                            </div>
                        </Link>
                    </>
                )}

                <Link className="sidenav-a" to="/help">
                    <div
                        className={
                            currentPanel === SideDrawerTab.help
                                ? "sidenav-button active"
                                : "sidenav-button"
                        }
                        onClick={() => {
                            saveCurrentPanel(SideDrawerTab.help);
                            handleMobileEvent("mobile");
                        }}
                        key="Help"
                    >
                        <i className="far fa-life-ring"></i>
                        <p>Help</p>
                    </div>
                </Link>
            </List>
            <Divider />
        </div>
    );
};

export default SideDrawer;
