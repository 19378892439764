import { ProductWebflowFieldInterface } from "./Product";

export interface ProductEditorImageMetaInterface {
    prevFileLength: number,
    lastUpdated: number,
    meta: {
        featuredImage?: string,
        Hero_Section_1_Image?: string,
        Hero_Section_2_Image?: string,
    },
    paths: string[]
}

const WebflowFieldOrder = [
    "Product Category",
    "Product Subtitle",
    "Tags",
    "Assembled Weight",
    "Video Embed Url",
    "Option Title",
    "Product Options",
    "Bundled Product",
    "Related Products",
    "SEO Title",
    "SEO Description",
    "Product Summary",
    "Main Features",
    "Hero Section 1 Copy",
    "Hero Section 2 Copy",
    "Hero Section 3 Copy",
    "Sale Price / Certifications"
];

export const sortWebflowFields = (unsortedList: ProductWebflowFieldInterface[]) => {
    unsortedList.sort((a, b) => {
        if (a.fieldType > b.fieldType) {
            return -1;
        }
        if (a.fieldType < b.fieldType) {
            return 1;
        }
        return 0;
    });

    unsortedList.sort((a, b) => {
        let ai = WebflowFieldOrder.indexOf(a.name);
        let bi = WebflowFieldOrder.indexOf(b.name);

        if(ai == -1) ai = 100;
        if(bi == -1) bi = 100;

        if(ai != bi) {
            return (ai < bi) ? -1 : 1;
        }

        return 0;
    });

    return unsortedList;
};