import React from "react";
import _ from "lodash";

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, ButtonGroup, Grid, ListItem, ListItemText, Paper, TextField, Typography } from "@material-ui/core";
import { makeAxiosCall } from "utils";
import Loading from "components/utils/Loading";
import { List, WindowScroller } from "react-virtualized";
import { ProductVendorInterface } from "interfaces/Product";
import { ArrowDownward, ArrowUpward, ExpandMore } from "@material-ui/icons";
import { ProductContext } from "context/Product";
import { Link } from "react-router-dom";

interface VendorListItem {
  vendor: ProductVendorInterface,
  products: {
    id: number,
    name: string,
    imageUrl?: string
  }[],
}

enum SortKeys {
  Quantity = "products.length",
  Name = "vendor.name",
}

enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

const VendorList = () => {
    const { products } = React.useContext(ProductContext);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [vendors, setVendors] = React.useState<VendorListItem[]>([]);
    const [expandedVendors, setExpandedVendors] = React.useState([]);
    const [searchInput, setSearchInput] = React.useState<string>("");
    const [sortKey, setSortKey] = React.useState(SortKeys.Quantity);
    const [sortDirection, setSortDirection] = React.useState(SortDirection.Desc);

    const windowScrollerRef =React.useRef<WindowScroller>();
    const virtualizedListRef = React.useRef<List>();

    let isMounted = true;

    const singleProductRowHeight = 56;

    React.useEffect(() => {
        fetchVendors();

        return () => {
            isMounted = false;
        };
    }, []);

    const fetchVendors = async () => {
      const response = await makeAxiosCall(
        "get",
        `vendors`,
      );

      if (response?.status == 200) {
        // Build vendor objects
        let rawVendorList: any[] = response.data;

        const newVendorList = rawVendorList.map((item) => {
          return {
            vendor: {
              id: item["id"],
              name: item["name"],
            },
            products: item["products"].map((prod) => {
              return {
                id: prod.id,
                name: prod.name,
                imageUrl: products.find((p) => p.id == prod.id).imageUrl
              }
            })
          }
        });

        // Set data
        if (isMounted) setVendors(newVendorList);
      } else {
        console.error("Failed to load vendors:");
        console.error(response)
      }

      if (isMounted) setLoading(false);
    }

    const sortVendors = React.useCallback((vendorList: VendorListItem[]) => {
      return _.orderBy(vendorList, sortKey, sortDirection);
    }, [sortKey, sortDirection]);

    const visibleVendors = React.useMemo(() => {
      let cleanInput = searchInput.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "");

      // Get vendors that match the search term
      let vendorHits: VendorListItem[] = _.filter(vendors, (vendor: VendorListItem) => {
        return _.includes(vendor.vendor.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, ""), cleanInput);
      });

      // Get products that match the search term
      const productHits: VendorListItem[] = _.filter(vendors, (vendor: VendorListItem) => {
        return _.some(vendor.products, (p) => p.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, "").indexOf(cleanInput) > -1);
      }).map((item) => {
        return {
          ...item,
          products: _.filter(item.products, (product) => {
            return _.includes(product.name.toLowerCase().replace(/[^a-zA-Z\d\.\:]/g, ""), cleanInput);
          })
        }
      });

      // Show all products of matching vendors, but only matching products for other vendors
      for (let productHit of productHits) {
        if (!_.some(vendorHits, (hit: VendorListItem) => hit.vendor.id == productHit.vendor.id)) {
          vendorHits.push(productHit);
        }
      } 

      return sortVendors(vendorHits);
    }, [vendors, searchInput, sortKey, sortDirection])

    const buildSearch = () => {
      return (
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant={"body2"} style={{ fontWeight: "bold" }}>Search</Typography>
              <TextField
                  label="Vendor / Product Search"
                  variant="outlined"
                  onChange={(e) => setSearchInput(e.target.value)}
                  fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <Typography variant={"body2"} style={{ fontWeight: "bold" }}>Sort</Typography>
              <ButtonGroup fullWidth={true} color="primary" aria-label="outlined primary button group" style={{height: 56}}>
                <Button 
                  className={sortKey == SortKeys.Name ? "active-filter-button" : "inactive-filter-button" }
                  onClick={() => {
                    setSortKey(SortKeys.Name);
                  }}
                >
                  Name
                </Button>
                <Button
                  className={sortKey == SortKeys.Quantity ? "active-filter-button" : "inactive-filter-button" }
                  onClick={() => {
                    setSortKey(SortKeys.Quantity);
                  }}
                >
                  Quantity
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography variant={"body2"} style={{ fontWeight: "bold" }}>Direction</Typography>
              <ButtonGroup fullWidth={true} color="primary" aria-label="outlined primary button group" style={{height: 56}}>
                <Button 
                  className={sortDirection == SortDirection.Asc ? "active-filter-button" : "inactive-filter-button" }
                  onClick={() => {
                    setSortDirection(SortDirection.Asc);
                  }}
                >
                  <ArrowUpward />
                </Button>
                <Button
                  className={sortDirection == SortDirection.Desc ? "active-filter-button" : "inactive-filter-button" }
                  onClick={() => {
                    setSortDirection(SortDirection.Desc);
                  }}
                >
                  <ArrowDownward />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
      );
    }

    const calculateRowHeight = (index: number): number => {
      let calculatedHeight = 90;  // Unexpanded accordion height
      const expandedAccordionPadding = 16;

      const vendor = visibleVendors[index];
      if (expandedVendors[vendor.vendor.id]) {
        calculatedHeight += (vendor.products.length * singleProductRowHeight) + expandedAccordionPadding;
      }

      return calculatedHeight;
    }

    const renderPlaceholder = () => {
      return <h3 className="body-message center-text">No vendors meet your search parameters.</h3>;
    }

    const toggleAccordian = (listKey: number, shouldExpand: boolean, index: number) => {
      setExpandedVendors({ ...expandedVendors, [listKey]: shouldExpand });
      virtualizedListRef.current.recomputeRowHeights(index);
      virtualizedListRef.current.forceUpdate();
    }

    const renderProduct = (product) => {
      return (
        <Grid item xs={12} key={product.id} >
          <Paper elevation={2} style={{ height: "90%", padding: "0px 15px" }}>
            <Grid 
                container 
                alignItems="center" 
                style={{
                    height: singleProductRowHeight - 8,
                    borderBottom: "none"
                }} 
                component={Link} 
                to={`../products/${product.id}`} 
                target={"_blank"}
            >
              <Grid item xs={2} md={1}>
                  <Avatar alt={product.name} src={product.imageUrl}>
                    {product.name.substring(0, 1)}
                  </Avatar>
              </Grid>
              <Grid item xs={10} md={11}>
                  <Typography noWrap variant="body2" style={{ fontWeight: "bold" }}>
                      {product.name}
                  </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }

    const renderRow = (index: number, key: string, style) => {
      let vendor: VendorListItem = visibleVendors[index];
      return (
        <div key={key} style={style} className={"single-product-line"}>
          <Accordion
            key={"vendor-list-" + vendor.vendor.id}
            TransitionProps={{ timeout: 0 }}
            style={{ backgroundColor: "white" }}
            elevation={2}
            expanded={expandedVendors[vendor.vendor.id] ? true : false}
            disabled={vendor.products.length == 0}
            onChange={(e, shouldExpand) => {
                toggleAccordian(vendor.vendor.id, shouldExpand, index);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />} style={{ fontWeight: "bold", height: 90, margin: -4, boxShadow: "none" }} className="line-item">
              {vendor.vendor.name + " (" + vendor.products.length + ")"}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                  {
                    _.orderBy(vendor.products, ['name'], ['asc']).map((product) => renderProduct(product))
                  }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }

    const buildResults = () => {
      if (loading) {
        return <Loading height="100vh" title={"Loading Vendors"} position={"center"} />;
      } else {
        return <WindowScroller ref={windowScrollerRef}>
            {({ height, width, isScrolling, onChildScroll, scrollTop }) => {
                return (<Grid container className="results">
                    <List
                        ref={virtualizedListRef}
                        autoHeight
                        width={width}
                        height={height}
                        rowHeight={(rowIndex) => calculateRowHeight(rowIndex.index)}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        rowRenderer={(itemProps) => renderRow(itemProps.index, itemProps.key, itemProps.style)}
                        noRowsRenderer={renderPlaceholder}
                        rowCount={visibleVendors.length}
                        containerStyle={{ backgroundColor: "transparent", paddingTop: 10 }}
                        overscanRowCount={3}
                    />
                </Grid>)
            }}
        </WindowScroller>
      }
    }

    return (
        <div className="admin view">
            <Grid container style={{ marginBottom: 10 }}>
                <Grid item md={10} xs={6}>
                    <h1 className="reveal-text">Vendors</h1>
                </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent={"center"}>
              {buildSearch()}
              {buildResults()}
            </Grid>
        </div>
    );
};

export default VendorList;
