import { BasicCompanyInterface, buildCompanyInfo, CompanyInterface } from "./Company";
import { BasicProductInterface, buildBasicProductInterface, productFromHasuraObject, ProductInterface } from "./Product";
import { BasicUserInterface, UserInterface } from "./User";

export interface DemoFeedbackInterface {
    id: number,
    formType: string,
    createdBy?: BasicUserInterface
    dateOfDemo: Date,
    createdAt: Date,
    demoForExistingReseller: boolean,
    targetReseller?: BasicCompanyInterface,
    potentialResellerName?: string,
    rep?: BasicCompanyInterface,
    productsDemoed: DemoFeedbackProductInterface[],
    projectPurpose: string,
    opportunityNotes: string,
    likelihoodToCloseDeal: string,
    howCanWeHelp: string,
    listKey: string,
    notes: string,
}

export const buildDemoFeedbackInterface = (data): DemoFeedbackInterface => {

    let productsDemoed = [];

    for (let pdi = 0; pdi < data.products.length; pdi++) {
        const productDemoed = buildDemoFeedbackProductInterface(data.products[pdi]);

        if(productDemoed != null) productsDemoed.push(productDemoed);
    }

    return {
        id: data.id,
        formType: data.form_type,
        createdBy: data.created_by,
        dateOfDemo: new Date(data.date_of_demo),
        createdAt: new Date(data.created_at),
        demoForExistingReseller: data.demo_for_existing_reseller,
        potentialResellerName: data.potential_reseller_name,
        targetReseller: data.target_reseller ? {id: data.target_reseller.id, name: data.target_reseller.name} : null,
        rep: {id: data.rep.id, name: data.rep.name},
        productsDemoed: productsDemoed,
        projectPurpose: data.project_purpose,
        opportunityNotes: data.opportunity_notes,
        likelihoodToCloseDeal: data.likelihood_to_close_deal,
        howCanWeHelp: data.how_can_we_help,
        listKey: 'd' + data.id,
        notes: data.notes,
    };
};



// This is way overly complicated. - Devon (also made by Devon)
export const buildFeedbackListsFromRaw = (rawFeedback): DemoFeedbackInterface[] => {
    let fullFeedbackList: DemoFeedbackInterface[] = [];


    for (let fi = 0; fi < rawFeedback.length; fi++) {
        const singleRawFeedback = rawFeedback[fi];
        fullFeedbackList.push(buildDemoFeedbackInterface(singleRawFeedback));
    }

    return fullFeedbackList;
}


export const buildFeedbackSearchLists = (allFeedback: DemoFeedbackInterface[], allReps: BasicCompanyInterface[], allRepUsers: BasicUserInterface[]) => {
    let fullFeedbackList: DemoFeedbackInterface[] = [];
    let fullRepFeedbackList: DemoFeedbackRepReportInterface[] = [];
    let fullUserFeedbackList: DemoFeedbackUserReportInterface[] = [];
    let fullProductFeedbackList: DemoFeedbackProductReportInterface[] = [];

    if(allReps) {
        for (let ri = 0; ri < allReps.length; ri++) {
            const basicRep = allReps[ri];

            let basicUserList = allRepUsers.filter((u) => u.companyId == basicRep.id);

            let userList = [];

            for (let ui = 0; ui < basicUserList.length; ui++) {
                const basicUser = basicUserList[ui];
                userList.push({
                    id: basicUser.id,
                    name: basicUser.name,
                    demoFeedback: [],
                    products: [],
                    listKey:  'r' + basicRep.id + 'u' + basicUser.id
                });
            }

            fullRepFeedbackList.push({
                id: basicRep.id,
                name: basicRep.name,
                numberDemoed: 0,
                users: userList,
                listKey: 'r' + basicRep.id
            })
        }
    }


    for (let ui = 0; ui < allRepUsers.length; ui++) {
        const basicUser = allRepUsers[ui];
        fullUserFeedbackList.push({
            id: basicUser.id,
            name: basicUser.name,
            demoFeedback: [],
            products: [],
            listKey: 'u' + basicUser.id
        });
    }

    for (let rfdi = 0; rfdi < allFeedback.length; rfdi++) {
        let builtFeedbackData = allFeedback[rfdi];
        // if(!builtFeedbackData.createdBy) builtFeedbackData = buildDemoFeedbackInterface(builtFeedbackData);
        

        // Fill the rep list
        fullFeedbackList.push(builtFeedbackData);

        const existingRepIndex = fullRepFeedbackList.findIndex((fl) => fl.id == builtFeedbackData.rep.id);
        if(existingRepIndex > -1) {
            const existinguserIndex = fullRepFeedbackList[existingRepIndex].users.findIndex((fl) => fl.id == builtFeedbackData.createdBy.id);
            if(existinguserIndex > -1) {
                fullRepFeedbackList[existingRepIndex].users[existinguserIndex].demoFeedback.push(builtFeedbackData);
            } else {
                fullRepFeedbackList[existingRepIndex].users.push({
                    id: builtFeedbackData.createdBy.id,
                    name: builtFeedbackData.createdBy.name,
                    demoFeedback: [builtFeedbackData],
                    products: [],
                    listKey: 'r' + fullRepFeedbackList[existingRepIndex].id + 'u' + builtFeedbackData.createdBy.id
                });
            }

            fullRepFeedbackList[existingRepIndex].numberDemoed++;
        } else {
            fullRepFeedbackList.push({
                id: builtFeedbackData.rep.id,
                name: builtFeedbackData.rep.name,
                numberDemoed: 1,
                users: [{
                    id: builtFeedbackData.createdBy.id,
                    name: builtFeedbackData.createdBy.name,
                    demoFeedback: [builtFeedbackData],
                    products: [],
                    listKey: 'r' + builtFeedbackData.rep.id + 'u' + builtFeedbackData.createdBy.id
                }],
                listKey: 'r' + builtFeedbackData.rep.id
            });
        }


        // Fill the User List
        const existingUserIndex = fullUserFeedbackList.findIndex((fl) => fl.id == builtFeedbackData.createdBy.id);
        if(existingUserIndex > -1) {
            fullUserFeedbackList[existingUserIndex].demoFeedback.push(builtFeedbackData);
        } else {
            fullUserFeedbackList.push({
                id: builtFeedbackData.createdBy.id,
                name: builtFeedbackData.createdBy.name,
                demoFeedback: [builtFeedbackData],
                products: [],
                listKey: 'u' + builtFeedbackData.createdBy.id
            });
        }


        for (let pdi = 0; pdi < builtFeedbackData.productsDemoed.length; pdi++) {
            const demoedProduct = builtFeedbackData.productsDemoed[pdi];
            
            // Fill the Product List
            const existingProductIndex = fullProductFeedbackList.findIndex((fl) => fl.productId == demoedProduct.product.id);
            if(existingProductIndex > -1) {
                const existingProduct = fullProductFeedbackList[existingProductIndex];
                
                // Fill the product-rep list
                const existingProductRepIndex = existingProduct.reps.findIndex((fl) => fl.id == builtFeedbackData.rep.id);
                if(existingProductRepIndex > -1) {
                    const existingProductRepUserIndex = existingProduct.reps[existingProductRepIndex].users.findIndex((fl) => fl.id == builtFeedbackData.createdBy.id);
                    if(existingProductRepUserIndex > -1) {
                        existingProduct.reps[existingProductRepIndex].users[existingProductRepUserIndex].demoFeedback.push(builtFeedbackData);
                    } else {
                        existingProduct.reps[existingProductRepIndex].users.push({
                            id: builtFeedbackData.createdBy.id,
                            name: builtFeedbackData.createdBy.name,
                            demoFeedback: [builtFeedbackData],
                            products: [],
                            listKey: 'p' + existingProduct.id + 'r' + existingProduct.reps[existingProductRepIndex].id + 'u' + builtFeedbackData.createdBy.id
                        });
                    }

                    existingProduct.reps[existingProductRepIndex].numberDemoed++;
                } else {
                    existingProduct.reps.push({
                        id: builtFeedbackData.rep.id,
                        name: builtFeedbackData.rep.name,
                        numberDemoed: 1,
                        users: [{
                            id: builtFeedbackData.createdBy.id,
                            name: builtFeedbackData.createdBy.name,
                            demoFeedback: [builtFeedbackData],
                            products: [],
                            listKey: 'p' + existingProduct.id + 'r' + builtFeedbackData.rep.id + 'u' + builtFeedbackData.createdBy.id
                        }],
                        listKey: 'p' + existingProduct.id + 'r' + builtFeedbackData.rep.id
                    });
                }


                // Fill the User List
                const existingProductUserIndex = existingProduct.repUsers.findIndex((fl) => fl.id == builtFeedbackData.createdBy.id);
                if(existingProductUserIndex > -1) {
                    existingProduct.repUsers[existingProductUserIndex].demoFeedback.push(builtFeedbackData);
                } else {
                    existingProduct.repUsers.push({
                        id: builtFeedbackData.createdBy.id,
                        name: builtFeedbackData.createdBy.name,
                        demoFeedback: [builtFeedbackData],
                        products: [],
                        listKey: 'p' + existingProduct.id + 'u' + builtFeedbackData.createdBy.id
                    });
                }
                existingProduct.numberDemoed++;
            } else {
                fullProductFeedbackList.push({
                    id: demoedProduct.id,
                    productId: demoedProduct.product.id,
                    name: demoedProduct.product.name,
                    imageUrl: demoedProduct.product.imageUrl,
                    numberDemoed: 1,
                    reps: [{
                        id: builtFeedbackData.rep.id,
                        name: builtFeedbackData.rep.name,
                        numberDemoed: 1,
                        users: [{
                            id: builtFeedbackData.createdBy.id,
                            name: builtFeedbackData.createdBy.name,
                            demoFeedback: [builtFeedbackData],
                            products: [],
                            listKey: 'p' + demoedProduct.id + 'r' + builtFeedbackData.rep.id + 'u' + builtFeedbackData.createdBy.id
                        }],
                        listKey: 'p' + demoedProduct.id + 'r' + builtFeedbackData.rep.id
                    }],
                    repUsers: [{
                        id: builtFeedbackData.createdBy.id,
                        name: builtFeedbackData.createdBy.name,
                        demoFeedback: [builtFeedbackData],
                        products: [],
                        listKey: 'p' + demoedProduct.id + 'u' + builtFeedbackData.createdBy.id
                    }],
                    listKey: 'p' + demoedProduct.id
                });
            }
        }
    }

    for (let rfli = 0; rfli < fullRepFeedbackList.length; rfli++) {
        const repFeedback = fullRepFeedbackList[rfli];

        // Build cyclical product/user relationship
        for (let ufli = 0; ufli < repFeedback.users.length; ufli++) {
            const userFeedback = repFeedback.users[ufli];

            for (let uffli = 0; uffli < userFeedback.demoFeedback.length; uffli++) {
                const userDemoFeedback = userFeedback.demoFeedback[uffli];
                
                for (let udfpi = 0; udfpi < userDemoFeedback.productsDemoed.length; udfpi++) {
                    const userDemoFeedbackProduct = userDemoFeedback.productsDemoed[udfpi];

                    let productIndex = userFeedback.products.findIndex((p) => p.productId == userDemoFeedbackProduct.product.id);
                    
                    if(productIndex > -1) {
                        userFeedback.products[productIndex].demoFeedback.push(userDemoFeedback);
                        userFeedback.products[productIndex].numberDemoed++; // unnecessary
                    } else {
                        userFeedback.products.push({
                            id: userDemoFeedbackProduct.id,
                            productId: userDemoFeedbackProduct.product.id,
                            name: userDemoFeedbackProduct.product.name,
                            imageUrl: userDemoFeedbackProduct.product.imageUrl,
                            numberDemoed: 1,
                            demoFeedback: [userDemoFeedback],
                            listKey: 'u' + userFeedback.id +  'p' + userDemoFeedbackProduct.id
                        });
                    }
                }
            }

            const userListFeedbackIndex = fullUserFeedbackList.findIndex((u: DemoFeedbackUserReportInterface) => u.id == userFeedback.id);
            if(userListFeedbackIndex > -1) {
                fullUserFeedbackList[userListFeedbackIndex].products = fullUserFeedbackList[userListFeedbackIndex].products.concat(userFeedback.products);
            } else {
                console.log('WHERE IS THIS USER?! THIS SHOULD NEVER HAPPEN.')
            }
        }
        
    }



    return {fullFeedbackList, fullRepFeedbackList, fullUserFeedbackList, fullProductFeedbackList}
}


export const emptyDemoFeedbackForm = (): DemoFeedbackInterface => {
    return {
        id: 0,
        formType: "none",
        dateOfDemo: new Date(),
        createdAt: new Date(),
        demoForExistingReseller: false,
        productsDemoed: [emptyDemoFeedbackFormProduct()],
        projectPurpose: "",
        opportunityNotes: "",
        likelihoodToCloseDeal: "",
        howCanWeHelp: "",
        listKey: "",
        notes: ""
    };
}

export interface DemoFeedbackProductInterface {
    id: number,
    product?: BasicProductInterface,
    feedbackOnFeatures: boolean,
    feedbackOnPrice: boolean,
    feedbackOnFunctionality: boolean,
    feedbackNotes: string
}

export const emptyDemoFeedbackFormProduct = (): DemoFeedbackProductInterface => {
    return {
        id: 0,
        product: null,
        feedbackOnFeatures: false,
        feedbackOnPrice: false,
        feedbackOnFunctionality: false,
        feedbackNotes: ""
    }
}


export const buildDemoFeedbackProductInterface = (data): DemoFeedbackProductInterface => {

    return {
        id: data.id,
        product: buildBasicProductInterface(data.product),
        feedbackOnFeatures: data.feedback_on_features,
        feedbackOnPrice: data.feedback_on_price,
        feedbackOnFunctionality: data.feedback_on_functionality,
        feedbackNotes: data.feedback_notes
    }
}

export const LikelihoodToCloseDealOptions = [
    "It's closed!",
    "80+%",
    "50/50",
    "Life support",
    "It's dead..."
]

export const  DemoFeedbackProjectPurposeOptions = [
    "Production",
    "Integration/Install",
    "Retail/Etail"
]


export interface DemoFeedbackProductReportInterface {
    id: number,
    productId: number,
    name: string,
    numberDemoed: number,
    imageUrl?: string,
    reps?: DemoFeedbackRepReportInterface[], // used when internal
    repUsers?: DemoFeedbackUserReportInterface[],
    demoFeedback?: DemoFeedbackInterface[],  // used when inside of user interface
    listKey: string
}


export interface DemoFeedbackRepReportInterface {
    id: number,
    name: string,
    numberDemoed: number,
    users?: DemoFeedbackUserReportInterface[],
    listKey: string
}


export interface DemoFeedbackUserReportInterface {
    id: string,
    name: string,
    demoFeedback: DemoFeedbackInterface[],
    products: DemoFeedbackProductReportInterface[],
    listKey: string
}
