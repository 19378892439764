import React from "react";

import { logFileDownload, makeAxiosCall } from "utils";
import { productFromHasuraObject, ProductInterface, ProductMediaInterface } from "interfaces/Product";
import { Avatar, Button, Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Snackbar, TextField, useTheme, Zoom } from "@material-ui/core";
import { AddPhotoAlternate, GetApp, DeleteForever, Visibility, Add, FileCopy, Close, HourglassEmpty, FeaturedVideo, Save, ViewCarousel, DragHandle, FormatListNumbered, Movie } from "@material-ui/icons";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import 'scss/components/Utils/Upload.scss';
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import FileSaver from "file-saver";
import { Alert } from "@material-ui/lab";
import ProductEditorMediaFavoriteMenu from "./ProductEditorMediaFavoriteMenu";
import axios from "axios";
import { Draggable } from "react-drag-reorder";

interface ProductEditorMediaProps {
  product: ProductInterface, 
  setProduct: Function,
  writeAccess: boolean
}

interface VideoFormInterface {
  url: string, 
  name: string,
  order: number,
  vimeoId: string,
  thumbnail?: string
  scrubberTimestamps?: string
}

const EmptyVideoForm: VideoFormInterface = {
    url: "",
    name: "",
    order: 0,
    vimeoId: ""
}

const ProductEditorMedia = ({
  product,
  setProduct, 
  writeAccess 
}: ProductEditorMediaProps) => {
  const [videos, setVideos] = React.useState<ProductMediaInterface[]>([]);
  const [images, setImages] = React.useState<ProductMediaInterface[]>([]);
  const [imagesToUpload, setImagesToUpload] = React.useState<FileObject[]>([]);
  const [mediaBeingDeleted, setMediaBeingDeleted] = React.useState<ProductMediaInterface[]>([]);
  const [selectedMedia, setSelectedMedia] = React.useState<ProductMediaInterface[]>([]);
  const [downloads, setDownloads] = React.useState<ProductMediaInterface[]>([]);
  const [orderBeingSaved, setOrderBeingSaved] = React.useState(false);

  const [videoForm, setVideoForm] = React.useState<VideoFormInterface>(EmptyVideoForm);

  const [showImageUploader, setShowImageUploader] = React.useState(false);
  const [reorderHappened, setReorderHappened] = React.useState(false);
  const [showVideoForm, setShowVideoForm] = React.useState(false);
  const [showImageDragList, setShowImageDragList] = React.useState(false);
  const [showVideoDragList, setShowVideoDragList] = React.useState(false);
  const [showDownloadsUploader, setShowDownloadsUploader] = React.useState(false);
  const [errorMsg, setError] = React.useState("");
  const theme = useTheme();

  const [snackbar, setSnackbar] = React.useState({
    isOpen: false,
    message: '',
    severity: 'success',
  });

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  React.useEffect(() => {
    fetchProductDetails();
    setImages(product.media && product.media.length ? product.media.filter((m) => m.type.name == "image"): []);
    setDownloads(product.media && product.media.length ? product.media.filter((m) => m.type.name == "manual" || m.type.name == "fixture profile"): []);
    setVideos(product.media && product.media.length ? product.media.filter((m) => m.type.name == "video"): []);
  }, []);
  

  const fetchProductDetails = async () => {
    const response = await makeAxiosCall(
      "get",
      `product/${product.id}`
    );

    if (response?.status == 200) {
        const newProductData = productFromHasuraObject(response.data);
        setProduct(newProductData);
        setImages(newProductData.media && newProductData.media.length ? newProductData.media.filter((m) => m.type.name == "image"): []);
        setDownloads(newProductData.media && newProductData.media.length ? newProductData.media.filter((m) => m.type.name == "manual" || m.type.name == "fixture profile"): []);
        setVideos(newProductData.media && newProductData.media.length ? newProductData.media.filter((m) => m.type.name == "video"): []);
    }
  }

  const fetchVimeoInfo = async () => {
    if(videoForm.vimeoId.length < 3) {
        return;
    }

    try{
        const response = await axios({
            method: "GET",
            url: `https://api.vimeo.com/videos/${videoForm.vimeoId}`,
            headers: {
                Authorization: `Bearer 74a33824330868f775e67f19d422dbfd`,
            }
        });
    
        if (response?.status == 200) {
            const vimeoObject = response.data;
            setVideoForm({
                ...videoForm,
                url: vimeoObject.link,
                name: vimeoObject.name,
                thumbnail: (
                    vimeoObject.pictures && 
                    vimeoObject.pictures.sizes && 
                    vimeoObject.pictures.sizes.length
                ) ? vimeoObject.pictures.sizes[vimeoObject.pictures.sizes.length - 1].link : null,
                order: videos.length
            });

            setSnackbar({
                isOpen: true,
                message: "Nice, that video looks legit",
                severity: "success"
            });
        } else {
            setSnackbar({
                isOpen: true,
                message: "I couldn't find that video, check the console.",
                severity: "error"
            });
        }

    } catch(e) {
        console.log(e);
        setSnackbar({
            isOpen: true,
            message: "I couldn't find that video, check the console.",
            severity: "error"
        });
    }
  }

  const saveVideoForm = () => {
    makeAxiosCall("POST", "product-media/save", {
        name: videoForm.name,
        productId: product.id,
        url: videoForm.vimeoId,
        productMediaTypeId: 4,
        order: videoForm.order,
        scrubberTimestamps: videoForm.scrubberTimestamps
    }).then((_) => {
        setVideoForm({...EmptyVideoForm});
        setShowVideoForm(false);
        fetchProductDetails();
    }).catch((e) => {
        console.log(e);
        setSnackbar({
            isOpen: true,
            message: "Something went wrong while saving that video.",
            severity: "error"
        });
    });
  }

  const saveOrder = (mediaType: string) => {
    if(orderBeingSaved) {
        setSnackbar({
            isOpen: true,
            message: "Hold your horses, I'm still saving the last list",
            severity: "error"
        });
        return;
    } else {
        setOrderBeingSaved(true);
    }

    let mediaList = mediaType == "image" ? images : videos;

    setSnackbar({
        isOpen: true,
        message: "Saving the order of " + mediaType,
        severity: "success"
    });
    
    makeAxiosCall("POST", "product-media/reorder", {
        media: mediaList
    }).then((_) => {
        setSnackbar({
            isOpen: true,
            message: "Order Saved",
            severity: "success"
        });

        setOrderBeingSaved(false);
        setReorderHappened(false);
        setShowImageDragList(false);
        setShowVideoDragList(false);
        fetchProductDetails();
    }).catch((e) => {
        console.log(e);
        setSnackbar({
            isOpen: true,
            message: "Something went wrong while saving the order. Check the console.",
            severity: "error"
        });

        setOrderBeingSaved(false);
        setReorderHappened(false);
        setShowImageDragList(false);
        setShowVideoDragList(false);
    });
  }

  const deleteMedia = (media: ProductMediaInterface) => {

    setMediaBeingDeleted([...mediaBeingDeleted, media]);

    makeAxiosCall(
        "delete",
        `product-media/${media.id}`
    ).then(async (_) => {
        await fetchProductDetails();
        setMediaBeingDeleted(mediaBeingDeleted.filter((m) => m.name != media.name));
    });
  }

  const uploadMedia = (files: FileObject[], mediaType: number) => {
    if(files.length > 0) {
        let bodyFormData = new FormData();
        bodyFormData.append('productId', product.id.toString());
        bodyFormData.append('productMediaTypeId', mediaType.toString());

        let fileSize = 0;

        for (let fi = 0; fi < files.length; fi++) {
            const file = files[fi];

            if(product.media.find((m) => m.name == file.file.name)) {

            } else {
                fileSize = fileSize + file.file.size;
                
                bodyFormData.append('media', file.file, file.file.name);    
            }
        }


        if(fileSize > 100000000) {
            setSnackbar({
                isOpen: true,
                message: "That's a big file, or a lot of files. This might take a minute.",
                severity: "warning"
            });
        }

        makeAxiosCall("POST", "product-media", bodyFormData, {timeout: 300000, headers: { "Content-Type": "multipart/form-data",  }}).then((_) => {
            if(fileSize > 100000000) {
                setSnackbar({
                    isOpen: true,
                    message: "Done uploading. Ufda!",
                    severity: "success"
                });
            }

            fetchProductDetails();
        }).catch((e) => {
            console.log(e);
            setSnackbar({
                isOpen: true,
                message: "Something went wrong while uploading.",
                severity: "error"
            });
        });
    }
  }

  const handleSelectMedia = (mediaId) => {
    if (selectedMedia.some((i) => i.id == mediaId)) {
        setSelectedMedia(selectedMedia.filter((i) => i.id != mediaId));
    } else {
        setSelectedMedia([...selectedMedia, product.media.find((i) => i.id == mediaId)]);
    }
  }

  const handleSelectAllDownloads = () => {
    let downloadList = selectedMedia.filter((m) => m.type.name != "image" && m.type.name != "video");
    if (downloadList.length == downloads.length) {
        setSelectedMedia(selectedMedia.filter((m) => m.type.name == "image"));
    } else {
        let mergeArray = product.media.filter((m) => m.type.name != "image" && m.type.name != "video").concat(selectedMedia.filter((m) => m.type.name == "image"));
        setSelectedMedia([...mergeArray]);
    }
  }

  const handleSelectAllImages = () => {
    let imageList = selectedMedia.filter((m) => m.type.name == "image");
    if (imageList.length == images.length) {
        setSelectedMedia(selectedMedia.filter((m) => m.type.name != "image"));
    } else {
        let mergeArray = product.media.filter((m) => m.type.name == "image").concat(selectedMedia.filter((m) => m.type.name != "image"))
        setSelectedMedia([...mergeArray])
    }
  }

  const handleBulkDownload = async (mediaToDownload: ProductMediaInterface[], fileEnding: string, callback?: () => void) => {
    let zip = new JSZip();
    let count = 0;
    let zipFilename = `${product.name}${fileEnding}`;

    mediaToDownload.forEach((image, i) => {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(image.url, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        logFileDownload(image.name, image.url, image.id);
        zip.file(image.name, data, { binary: true });
        count++;
        if (count == mediaToDownload.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, zipFilename);
            if (callback) callback();
          });
        }
      });
    });
  };

  const clearSelected = () => {
    setSelectedMedia([]);
  }

  const downloadSelected = (fileEnding: string) => {
    handleBulkDownload(selectedMedia, fileEnding, clearSelected)
  }

  const downloadMedia = (media: ProductMediaInterface) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = media.name; // Name the file anything you'd like.
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", media.url);
    xhr.send();

    logFileDownload(media.name, media.url, media.id);
  }

  const viewMedia = (media: ProductMediaInterface) => {
    if(media.type.name == "video") {
        window.open(`https://vimeo.com/${media.url}`, "_blank");
    } else {
        window.open(media.url, "_blank");
    }
    
    logFileDownload(media.name, media.url, media.id);
  }

  const downloadAll = () => {
    handleBulkDownload(product.media.filter((m) => m.type.name != "video"), "-media-kit.zip");
  }

  const renderFavoriteButton = (image: ProductMediaInterface) => {
    return <ProductEditorMediaFavoriteMenu initialImage={image} product={product} setProduct={setProduct} />;
  }

  const renderImageRow = (image: ProductMediaInterface) => {
    
    return (
      <div key={image.id + "-image-list-item"}>
        <ListItem alignItems="flex-start">
            {!mediaBeingDeleted.find((m) => m.name == image.name) ? 
            <Grid container>
                <Grid item xs={4} md={1}>
                    <ListItemAvatar>
                        <Avatar alt={image.name} src={image.url} onClick={() => handleSelectMedia(image.id)} />
                    </ListItemAvatar>
                </Grid>
                <Grid item xs={8}>
                    <ListItemText 
                        primary={image.name.substring(0, image.name.lastIndexOf("."))}
                        secondary={
                        <React.Fragment>
                            {image.name.substring(image.name.lastIndexOf(".") + 1).toUpperCase()}
                        </React.Fragment>
                        }
                        onClick={() => handleSelectMedia(image.id)}
                    />
                </Grid>
                <Grid item xs={12} md={3} container justifyContent="flex-end">
                    <Grid item xs={2}>
                        {writeAccess ?
                            renderFavoriteButton(image)
                        : null}
                    </Grid>
                    <Grid item xs={2}>
                    {writeAccess ?
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={()=> deleteMedia(image)}
                        >
                            <DeleteForever
                                style={{ color: "#828282" }}
                            />
                        </IconButton> 
                    : null}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={()=> viewMedia(image)}
                        >
                            <Visibility
                                style={{ color: "#828282" }}
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={()=> downloadMedia(image)}
                        >
                            <GetApp  color="primary" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            checked={selectedMedia.some((i) => i.id == image.id)}
                            onChange={() => handleSelectMedia(image.id)}
                        />
                    </Grid>
                </Grid>
                
            </Grid> :
            <Grid container>
                <Grid item xs={2}>
                    <ListItemAvatar>
                        <Avatar alt={image.name} src={image.url} onClick={() => handleSelectMedia(image.id)} />
                    </ListItemAvatar>
                </Grid>
                <Grid item xs={9}>
                    <ListItemText 
                        primary={image.name.substring(0, image.name.lastIndexOf("."))}
                        secondary={
                        <React.Fragment>
                            {image.name.substring(image.name.lastIndexOf(".") + 1).toUpperCase()}
                        </React.Fragment>
                        }
                        onClick={() => handleSelectMedia(image.id)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        style={{
                            position: "relative",
                            right: 20,
                        }}
                        onClick={()=> console.log('hey')}
                    >
                        <HourglassEmpty />    
                    </IconButton>
                </Grid>
            </Grid>
                
            }

            
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  const renderDownloadRow = (download: ProductMediaInterface) => {

    return (
      <div key={download.id + "-image-list-item"}>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <FileCopy  onClick={() => handleSelectMedia(download.id)} />
            </ListItemAvatar>

            <ListItemText 
                primary={download.name.substring(0, download.name.lastIndexOf("."))}
                secondary={
                  <React.Fragment>
                    {download.name.substring(download.name.lastIndexOf(".") + 1).toUpperCase()}
                  </React.Fragment>
                }
                onClick={() => handleSelectMedia(download.id)}
            />

            {!mediaBeingDeleted.find((m) => m.name == download.name) ? (
            <div>
            { writeAccess ?
                <IconButton
                    style={{
                        position: "relative",
                        right: 20,
                    }}
                    onClick={()=> deleteMedia(download)}
                >
                    <DeleteForever
                        style={{ color: "#828282" }}
                    />
                </IconButton>
            : null }

            <IconButton
                style={{
                    position: "relative",
                    right: 20,
                }}
                onClick={()=> viewMedia(download)}
            >
                <Visibility
                    style={{ color: "#828282" }}
                />
            </IconButton>


            <IconButton
                style={{
                    position: "relative",
                    right: 20,
                }}
                onClick={()=> downloadMedia(download)}
            >
                <GetApp  color="primary" />
            </IconButton>
            </div>
            ): <div>
                <IconButton
                    style={{
                        position: "relative",
                        right: 20,
                    }}
                    onClick={()=> console.log('hey')}
                >
                    <HourglassEmpty />    
                </IconButton>
                
            </div>}

            <Checkbox
                checked={selectedMedia.some((i) => i.id == download.id)}
                onChange={() => handleSelectMedia(download.id)}
            />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  const renderVideoRow = (video: ProductMediaInterface) => {
    
    return (
      <div key={video.id + "-video-list-item"}>
        <ListItem alignItems="flex-start">
            {!mediaBeingDeleted.find((m) => m.name == video.name) ? 
            <Grid container>
                <Grid item xs={4} md={1}>
                    <Movie />
                </Grid>
                <Grid item xs={8}>
                    <ListItemText 
                        primary={video.name}
                    />
                </Grid>
                <Grid item xs={12} md={3} container justifyContent="flex-end">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    {writeAccess ?
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={()=> deleteMedia(video)}
                        >
                            <DeleteForever
                                style={{ color: "#828282" }}
                            />
                        </IconButton> 
                    : null}
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            style={{
                                position: "relative",
                                right: 20,
                            }}
                            onClick={()=> viewMedia(video)}
                        >
                            <Visibility
                                style={{ color: "#828282" }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
                
            </Grid> :
            <Grid container>
                <Grid item xs={2}>
                    <FeaturedVideo />
                </Grid>
                <Grid item xs={9}>
                    <ListItemText 
                        primary={video.name}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        style={{
                            position: "relative",
                            right: 20,
                        }}
                        onClick={()=> console.log('hey')}
                    >
                        <HourglassEmpty />    
                    </IconButton>
                </Grid>
            </Grid>
                
            }

            
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  const reorderItem = (currentPos: number, newPos: number, mediaType: string) => {
    if(currentPos != newPos) {
        if(!reorderHappened) {
            setReorderHappened(true);
        }

        switch(mediaType) {
            case "image":
                let newImageOrder: ProductMediaInterface[] = [];

                images.splice(newPos, 0, images.splice(currentPos, 1)[0]);

                for (let ii = 0; ii < images.length; ii++) {
                    const image = images[ii];
                    newImageOrder.push({
                        ...image,
                        order: ii
                    });
                }
                setImages(newImageOrder);
                break;
            case "video":
                let newVideoOrder: ProductMediaInterface[] = [];

                videos.splice(newPos, 0, videos.splice(currentPos, 1)[0]);

                for (let ii = 0; ii < videos.length; ii++) {
                    const video = videos[ii];
                    newVideoOrder.push({
                        ...video,
                        order: ii
                    });
                }
                setVideos(newVideoOrder);

                break;
        }
    }

  }

  const renderDragList = (mediaType: string) => {
    let list = mediaType == "image" ? images : videos;
    return (
        <Draggable key={"image-list-key"} onPosChange={(currentPos, newPos) => reorderItem(currentPos, newPos, mediaType)}>
            {list.map((image, idx) => {
                return (
                    <div key={image.id + "-image-list-item"} style={{listStyle: "none"}}>
                        <ListItem alignItems="flex-start">
                            <Grid container>
                                <Grid item xs={4} md={1}>
                                    <ListItemAvatar>
                                        <Avatar alt={image.name} src={image.url} />
                                    </ListItemAvatar>
                                </Grid>
                                <Grid item xs={6} md={9}>
                                    <ListItemText 
                                        primary={image.name.substring(0, image.name.lastIndexOf("."))}
                                        secondary={
                                        <React.Fragment>
                                            {image.name.substring(image.name.lastIndexOf(".") + 1).toUpperCase()}
                                        </React.Fragment>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} container justifyContent="flex-end">
                                    <DragHandle style={{height: "100%"}} />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>
                );
            })}
        </Draggable>
    );
  };

  const renderImageList = () => {

    const imageList = [];

    images.sort((a, b) => {
        if(a.order != null && b.order != null) {
            if(a.order < b.order) return -1
            else if(a.order > b.order) return 1;
            else return 0;
        } else {
            if(a.name < b.name) return -1
            else if(a.name > b.name) return 1;
            else return 0;
        }
    });


    if(showImageDragList) return renderDragList("image");

    for (let ii = 0; ii < images.length; ii++) {
        const image = images[ii];
        imageList.push(renderImageRow(image))
    }

    imageList.push(
        <ListItem key={"image-list-key-bottom"} alignItems="flex-start" style={{height: 50}}>
            <ListItemText primary={" "} />

            <Zoom
                in={selectedMedia.filter((m) => m.type.name == "image").length > 0}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${selectedMedia.filter((m) => m.type.name == "image").length > 0 ? transitionDuration.exit : 0}ms`,
                }}
                unmountOnExit
            >

                <Button
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    className="btn"
                    fullWidth={true}
                    onClick={() => {
                        downloadSelected("-images.zip");
                    }}
                    endIcon={
                        <GetApp />
                    }
                    style={{marginLeft: 15}}
                >
                    {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                </Button>
            </Zoom>

            <Checkbox
              checked={selectedMedia.filter((m) => m.type.name == "image").length == images.length}
              onChange={handleSelectAllImages}
            />
        </ListItem>
    );

    return (
        <List key={"image-list-key"} >
            {imageList}
        </List>
    );
  };

  const renderDownloadList = () => {
    const downloadList = [];

    for (let di = 0; di < downloads.length; di++) {
        const download = downloads[di];
        downloadList.push(renderDownloadRow(download))
    }


    downloadList.push(
        <ListItem key={"download-list-key-bottom"} alignItems="flex-start" style={{height: 50}}>
            <ListItemText primary={" "} />

            <Zoom
                in={selectedMedia.filter((m) => m.type.name != "image").length > 0}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${selectedMedia.filter((m) => m.type.name != "image").length > 0 ? transitionDuration.exit : 0}ms`,
                }}
                unmountOnExit
            >

                <Button
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    className="btn"
                    fullWidth={true}
                    onClick={() => {
                        downloadSelected("-downloads.zip");
                    }}
                    endIcon={
                        <GetApp />
                    }
                    style={{marginLeft: 15}}
                >
                    {"Zip and Download " + selectedMedia.length + " file" + (selectedMedia.length > 1 ? "s" : "")}
                </Button>
            </Zoom>

            <Checkbox
              checked={selectedMedia.filter((m) => m.type.name != "image").length == downloads.length}
              onChange={handleSelectAllDownloads}
            />
        </ListItem>
    );

    return (
        <List key={"download-list-key"}>
            {downloadList}
        </List>
    );
  };

  const renderVideoList = () => {
    const videoList = [];

    videos.sort((a, b) => {
        if(a.order < b.order) return -1
        else if(a.order > b.order) return 1;
        else return 0;
    });

    if(showVideoDragList) return renderDragList("video");

    for (let vi = 0; vi < videos.length; vi++) {
        const video = videos[vi];
        videoList.push(renderVideoRow(video))
    }

    return (
        <List key={"video-list-key"} style={{paddingBottom: 32}} >
            {videoList.length ? videoList : <h3 style={{textAlign: "center"}}>No videos</h3>}
        </List>
    );
  };

  const renderMediaUploader = (image: Boolean) => {
    return (<Grid item xs={12}>
        <div className="po-upload-container bordered-uploader">
            <h3>Upload {image ? "Image(s)" : "downloadable files"}</h3>
            <DropzoneAreaBase
                maxFileSize={1000000000}
                filesLimit={99}
                dropzoneClass="file-uploader"
                fileObjects={imagesToUpload}
                showPreviews={false}
                onAdd={(files) => uploadMedia(files, image ? 1 : 2)}
                onDropRejected={(file) => setError("There was an issue with that file.")}
            />
        </div>
    </Grid>);
  }

  const renderVideoForm = () => {
    return (<Grid item xs={12}>
        <div className="po-upload-container bordered-uploader">
            <h3>Add Vimeo Video</h3>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        style={{ width: "100%" }}
                        label="Vimeo ID"
                        margin="normal"
                        value={videoForm.vimeoId}
                        variant="outlined"
                        onChange={(e) => {
                            if(e.target.value != videoForm.vimeoId) {
                                setVideoForm({...EmptyVideoForm, vimeoId: e.target.value})
                            }
                        }}
                    />
                    <TextField
                        style={{ width: "100%" }}
                        label="Scrubber Timestamps"
                        helperText='[{ "label": "gobos", "timeStart": 1, "timeEnd": 5, "order": 1 }, { "label": "macros", "timeStart": 6, "timeEnd": 10, "order": 2 }]'
                        margin="normal"
                        multiline={true}
                        value={videoForm.scrubberTimestamps}
                        variant="outlined"
                        onChange={(e) => {
                            setVideoForm({...videoForm, scrubberTimestamps: e.target.value})
                        }}
                    />
                </Grid>
                {videoForm.url ? 
                <Grid 
                    container 
                    item 
                    xs={12}
                    style={{marginBottom: 12}}
                >

                    <Grid item xs={4} style={{
                        backgroundImage: `url(${videoForm.thumbnail})`,
                        backgroundSize: 'contain', 
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            style={{ width: "100%" }}
                            label="Name"
                            margin="normal"
                            value={videoForm.name}
                            variant="outlined"
                            onChange={(e) => {
                                setVideoForm({...videoForm, name: e.target.value})
                            }}
                        />
                    </Grid>
                </Grid>
                : <></>}
                <Button
                        variant="outlined"
                        color={videoForm.vimeoId ? "secondary" : "primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => {
                            if(videoForm.url) {
                                saveVideoForm();
                            } else {
                                fetchVimeoInfo();
                            }
                        }}
                        endIcon={
                            videoForm.url ? <Save /> : <ViewCarousel />
                        }
                    >
                        {videoForm.url ? "Save Video" : "Fetch Video"}
                    </Button>
            </Grid>
        </div>
    </Grid>);
  }

  return (
    <Grid container>
        <Grid item container xs={12}>
            {product.media && product.media.length > 0 && (
                <Grid item container xs={12} style={{ marginTop: 16 }}>
                    <Grid item xs={12} md={9}></Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="btn blue"
                            onClick={downloadAll}
                        >
                            Download Media Kit
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Grid item container xs={12} style={{paddingTop: 50}}>
                <Grid item container xs={9}>
                    <h3>Downloads</h3>
                </Grid>
                <Grid item container xs={12} md={3} style={{alignSelf: "end"}}>
                    {writeAccess ? 
                    <Button
                        type="submit"
                        variant="outlined"
                        color={showDownloadsUploader ? "secondary" : "primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => setShowDownloadsUploader(!showDownloadsUploader)}
                        endIcon={
                            showDownloadsUploader ? <Close /> : <Add />
                        }
                        style={{marginLeft: 15}}
                    >
                        {showDownloadsUploader ? "Done" : "Upload other(s)"}
                    </Button> : null}
                </Grid>
            </Grid>
            {
                showDownloadsUploader ? renderMediaUploader(false) : null
            }
            <Grid item xs={12}>
                {renderDownloadList()}
            </Grid>


            <Grid item container xs={12} style={{paddingTop: 50}}>
                <Grid item container xs={7}>
                    <h3>Videos</h3>
                </Grid>

                <Grid item container md={2} xs={12} style={{alignSelf: "end"}}>
                    { writeAccess ? 
                    <Button
                        type="submit"
                        variant={((showVideoDragList && reorderHappened) ? "contained" : "text")}
                        color={showVideoDragList && !reorderHappened ? "secondary" : "primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => {
                            if(showVideoDragList && reorderHappened) {
                                saveOrder("video");
                            } else if(!showImageDragList) {
                                setShowVideoDragList(!showVideoDragList);
                            }
                        }}
                        endIcon={
                            showVideoDragList ? (reorderHappened ? <Save /> : <Close />) : <FormatListNumbered />
                        }
                        style={{marginLeft: 15}}
                    >
                        {showVideoDragList ? (reorderHappened ? "Save" : "Cancel") : "Order"}
                    </Button> : null}
                </Grid>
                <Grid item container xs={12} md={3} style={{alignSelf: "end"}}>
                    {writeAccess ? 
                    <Button
                        type="submit"
                        variant="outlined"
                        color={showVideoForm ? "secondary" : "primary"}
                        className="btn"
                        fullWidth={true}
                        onClick={() => setShowVideoForm(!showVideoForm)}
                        endIcon={
                            showVideoForm ? <Close /> : <Add />
                        }
                        style={{marginLeft: 15}}
                    >
                        {showVideoForm ? "Done" : "Add video(s)"}
                    </Button> : null}
                </Grid>
            </Grid>
            {
                showVideoForm ? renderVideoForm() : null
            }
            <Grid item xs={12}>
                {renderVideoList()}
            </Grid>

            <Grid item container xs={12} md={7}>
                <h3>Images</h3>
            </Grid>


            <Grid item container md={2} xs={12} style={{alignSelf: "end"}}>
                { writeAccess ? 
                <Button
                    type="submit"
                    variant={(showImageDragList && reorderHappened ? "contained" : "text")}
                    color={showImageDragList && !reorderHappened ? "secondary" : "primary"}
                    className="btn"
                    fullWidth={true}
                    onClick={() => {
                        if(showImageDragList && reorderHappened) {
                            saveOrder("image");
                        } else if(!showVideoDragList) {
                            setShowImageDragList(!showImageDragList);
                        }
                    }}
                    endIcon={
                        showImageDragList ? (reorderHappened ? <Save /> : <Close />) : <FormatListNumbered />
                    }
                    style={{marginLeft: 15}}
                >
                    {showImageDragList ? (reorderHappened ? "Save" : "Cancel") : "Order"}
                </Button> : null}
            </Grid>

            <Grid item container md={3} xs={12} style={{alignSelf: "end"}}>
                { writeAccess ? 
                <Button
                    type="submit"
                    variant="outlined"
                    color={showImageUploader ? "secondary" : "primary"}
                    className="btn"
                    fullWidth={true}
                    onClick={() => {
                        setShowImageUploader(!showImageUploader);
                    }}
                    endIcon={
                        showImageUploader ? <Close /> : <AddPhotoAlternate />
                    }
                    style={{marginLeft: 15}}
                >
                    {showImageUploader ? "Done" : "Upload image(s)"}
                </Button> : null}
            </Grid>


            {
                showImageUploader ? renderMediaUploader(true) : null
            }
        </Grid>
        <Grid item xs={12}>
            {renderImageList()}
        </Grid>

      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          setSnackbar({ ...snackbar, isOpen: false });
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity={snackbar.severity == "success" ? "success" : snackbar.severity == "error" ? "error" : snackbar.severity == "info" ? "info" : "warning"}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ProductEditorMedia;
