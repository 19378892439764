import React, { useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Grid, List, ListItem, Paper, Radio, RadioGroup, Snackbar, TextareaAutosize, TextField } from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import "scss/components/Account/Wizard/ResellerInfo.scss";
import { makeAxiosCall } from "utils";
import { UserContext } from "context/User";
import { DemoFeedbackInterface, DemoFeedbackProductInterface, DemoFeedbackProjectPurposeOptions, emptyDemoFeedbackForm, emptyDemoFeedbackFormProduct, LikelihoodToCloseDealOptions } from "interfaces/DemoFeedback";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { BasicCompanyInterface } from "interfaces/Company";
import { Link } from "react-router-dom";
import { ProductContext } from "context/Product";
import { ProductInterface } from "interfaces/Product";
import { Add, ExpandMore, Save } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";

interface DemoFormErrorInterface {
    date: boolean,
    products: boolean,
    opportunityNotes: boolean,
    howCanWeHelp: boolean,
    reseller: boolean
}

const DemoFormErrorInterfaceDefaults = {
    date: false,
    products: false,
    opportunityNotes: false,
    howCanWeHelp: false,
    reseller: false
};

const initialSnackbarState: {
    isOpen: boolean,
    message: string,
    severity: Color,
  } = {
    isOpen: false,
    message: '',
    severity: 'success',
  };

const DemoFeedbackForm = () => {

    const { currentUser } = React.useContext(UserContext);
    const [formData, setFormData] = React.useState<DemoFeedbackInterface>(emptyDemoFeedbackForm());
    const [selectedReseller, setSelectedReseller] = React.useState<BasicCompanyInterface>({id: 0, name: ""});
    const [resellerList, setResellerList] = React.useState<BasicCompanyInterface[]>([]);
    const { products } = useContext(ProductContext);
    const [ productList, setProductList ]  = React.useState<ProductInterface[]>([]);
    const [formErrors, setFormErrors] = React.useState<DemoFormErrorInterface>(DemoFormErrorInterfaceDefaults);
    const [snackbar, setSnackbar] = React.useState(initialSnackbarState);
    const [saving, setSaving] = React.useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");

    const navigate = useNavigate();

    React.useEffect(() => {
        if (type && formData.formType == "none") {
            setFormData({...formData, formType: type});
        }
        _fetchResellerList();
    }, []);

    React.useEffect(() => {
        setProductList(products);
    }, [products]);

    const _fetchResellerList = () => {
        makeAxiosCall(
            "get",
            "companies/simple"
        ).then(res => {
            const companyList: BasicCompanyInterface[] = [];
            for (let ci = 0; ci < res.data.length; ci++) {
                const company = res.data[ci];
                companyList.push({ id: company.id, name: company.name });
            }
            setResellerList(companyList);
        });
    }

    const _buildDateFieldTitle = () => {
        switch (formData.formType) {
            case "phone-call":
                return "When was the call?";
            case "visit":
                return "When was the visit?";
            case "demo-feedback":
                return "When was the product demoed?";
            default:
                return "When?";
        }
        
    }


    const _buildFormSelector = () => {
        return (
            <Grid item xs={12}>
                <h3>Interaction Type:</h3>
                <Grid item xs={12}>
                    <RadioGroup row onChange={(e) => {
                        setFormData({...formData, formType: e.target.value})
                    }}>
                        <FormControlLabel control={<Radio value={"phone-call"} checked={formData.formType === "phone-call"} />} label="Phone Call"  />
                        <FormControlLabel control={<Radio value={"visit"} checked={formData.formType === "visit"} />} label="Reseller Visit" />
                        <FormControlLabel control={<Radio value={"demo-feedback"} checked={formData.formType === "demo-feedback"} />} label="Demo" />
                    </RadioGroup>
                </Grid>
            </Grid>
        );
    }


    const _buildselectedForm = () => {
        switch (formData.formType) {
            case "phone-call":
            case "visit":
                return (
                    <Grid item xs={12} container spacing={2}>
                        {_buildDemoDate()}
                        {_buildDemoTarget()}
                        {_buildFreeTextField()}
                        <Accordion key={"oportunity"} style={{width: "100%"}}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <h3>Project Details (optional)</h3>
                            </AccordionSummary>
                            <AccordionDetails>{_buildOpportunityFields()}</AccordionDetails>
                        </Accordion>
                        {_buildSubmitButton()}
                    </Grid>
                ); 
            case "demo-feedback":
                return (
                    <Grid item xs={12} container spacing={2}>
                        {_buildDemoDate()}
                        {_buildDemoTarget()}
                        {_buildProductList()}
                        {_buildOpportunityFields()}
                        {_buildSubmitButton()}
                    </Grid>
                );
            default:
                return (
                    <Grid item xs={12}>
                    </Grid>
                );
        }
    }

    
    const _buildFreeTextField = () => {
        return (
            <Grid item xs={12}>
                <TextField
                    aria-label="basic-interaction-details"
                    label={"Notes"}
                    variant="outlined"
                    multiline={true}
                    fullWidth={true}
                    placeholder=""
                    value={formData.notes}
                    onChange={(e) => {
                        setFormData({...formData, notes: e.target.value})
                    }}
                />
            </Grid>
        );
    }

    const _buildDemoDate = () => {
        return (
            <Grid item md={6} xs={12} container>
                <Grid item xs={12}>
                    <h3>{_buildDateFieldTitle()}</h3>
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        style={{ width: "100%" }}
                        error={formErrors.date}
                        inputVariant="outlined"
                        format={"MM/DD/yy"}
                        label={_buildDateFieldTitle()}
                        views={["date"]}
                        disableFuture={true}
                        value={formData.dateOfDemo}
                        onChange={(date) => {
                            setFormData({...formData, dateOfDemo: date.toDate()})
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
    
    const _buildDemoTarget = () => {
        return (
            <Grid item md={6} xs={12} container>
                <Grid item md={4} xs={12}>
                    <h3>Who was it?</h3>
                </Grid>
                
                <Grid item md={8} xs={12}>
                    <RadioGroup row onChange={(e) => {
                        setFormData({...formData, demoForExistingReseller: e.target.value == "true"})
                    }}>
                        <FormControlLabel control={<Radio value={true} checked={formData.demoForExistingReseller === true} />} label="Existing Reseller"  />
                        <FormControlLabel control={<Radio value={false} checked={formData.demoForExistingReseller !== true} />} label="Potential Reseller" />
                    </RadioGroup>
                </Grid>
                {formData.demoForExistingReseller ? <Grid item md={11} xs={9}>
                    <Autocomplete
                        value={selectedReseller}
                        disabled={!formData.demoForExistingReseller}
                        fullWidth={true}
                        options={resellerList}
                        freeSolo={true}
                        onChange={(event, newValue) => {
                            if(typeof newValue == "string") {
                                // console.log('IS THIS A STRING?');
                                // console.log(newValue);
                            } else {
                                // console.log("NOT A STRING!");
                                setSelectedReseller(newValue);
                                setFormData({...formData, targetReseller: newValue});
                            }
                            
                        }}
                        getOptionLabel={(reseller) => reseller.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Select the reseller"}
                                variant="outlined"
                                error={formErrors.reseller}
                                fullWidth
                            />
                        )}
                    />
                </Grid> : <Grid item md={11} xs={9}>
                    <TextField
                        aria-label="potential-reseller-name"
                        label={"Potential Reseller Name"}
                        variant="outlined"
                        fullWidth={true}
                        placeholder=""
                        value={formData.potentialResellerName}
                        onChange={(e) => {
                            setFormData({...formData, potentialResellerName: e.target.value})
                        }}
                    />
                </Grid>}
                
                {formData.demoForExistingReseller && selectedReseller && selectedReseller.id != 0 && <Grid item md={1} xs={3}>
                    <Link to={"/company?reseller=" + selectedReseller.id} target="_blank" rel="noopener noreferrer" style={{paddingLeft: 8, borderBottomColor: "transparent"}}>
                        <Button 
                            variant="contained"
                            color="primary"
                            style={{height: "100%"}}
                        >
                            <i className="fas fa-eye blue"></i>
                        </Button>
                    </Link>
                </Grid>}
            </Grid>
        );
    }

    const _buildOpportunityFields = () => {
        return (
            <Grid item xs={12} container>
                <h3>What project is/are the product(s) being considered for?</h3>
                <Grid item xs={12}>
                    <RadioGroup row onChange={(e) => {
                        setFormData({...formData, projectPurpose: e.target.value})
                    }}>
                        {DemoFeedbackProjectPurposeOptions.map((option) => {
                            return <FormControlLabel key={option + "-DemoFeedbackProjectPurposeOptions"} control={<Radio value={option} checked={formData.projectPurpose === option} />} label={option}  />
                        })}
                    </RadioGroup>
                </Grid>

                <h3>What additional details do you have on the opportunity? For example, product quantities, timeline, competition, etc.</h3>
                <Grid item xs={12}>
                    <TextField
                        aria-label="opportunity-details"
                        label={"Opportunity Details"}
                        variant="outlined"
                        fullWidth={true}
                        multiline={true}
                        error={formErrors.opportunityNotes}
                        placeholder="Enter any project specifics here...,
                        e.g., quantities, timeline, end user, etc."
                        value={formData.opportunityNotes}
                        onChange={(e) => {
                            setFormData({...formData, opportunityNotes: e.target.value})
                        }}
                        className="order-item-notes"
                    />
                </Grid>

                <h3>What is the likelihood to close this deal?</h3>
                <Grid item xs={12}>
                    <RadioGroup row onChange={(e) => {
                        setFormData({...formData, likelihoodToCloseDeal: e.target.value})
                    }}>
                        {LikelihoodToCloseDealOptions.map((option) => {
                            return <FormControlLabel key={option + "-LikelihoodToCloseDealOptions"} control={<Radio value={option} checked={formData.likelihoodToCloseDeal === option} />} label={option}  />
                        })}
                    </RadioGroup>
                </Grid>


                <h3>What, if anything, can Blizzard do to help?</h3>
                <Grid item xs={12}>
                    <TextField
                        aria-label="notes"
                        label={"How can we help?"}
                        variant="outlined"
                        fullWidth={true}
                        multiline={true}
                        error={formErrors.howCanWeHelp}
                        placeholder="Share what we can do to help with this deal or in general..."
                        value={formData.howCanWeHelp}
                        onChange={(e) => {
                            setFormData({...formData, howCanWeHelp: e.target.value})
                        }}
                        className="order-item-notes"
                    />
                </Grid>
            </Grid>
        );
    }

    const _buildProductList = () => {
        let productsDemoedElementList = []
        let canAddAnother = true;

        for (let pdi = 0; pdi < formData.productsDemoed.length; pdi++) {
            const productDemoed = formData.productsDemoed[pdi];
            if(!productDemoed.product) {
                canAddAnother = false;
            }
            productsDemoedElementList.push(_buildProductDemoed(productDemoed, pdi));
        }

        return (
            <Grid item xs={12} container>
                <h3>What product(s) were demoed?</h3>
                <Grid item xs={12}>
                    <List>
                        {productsDemoedElementList}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => {
                            if(canAddAnother) {
                                let productList = [...formData.productsDemoed];

                                productList.push(emptyDemoFeedbackFormProduct());
                                setFormData({...formData, productsDemoed: productList});
                            }
                        }}
                        disabled={!canAddAnother}
                        variant="contained"
                        color="primary"
                        className="btn"
                    >
                        <Add /> Add more products
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const _buildProductDemoed = (productDemoed: DemoFeedbackProductInterface, productIndex: number) => {
        return (
            <ListItem key={productIndex + "-demoed-products-list-item"} style={{padding: 0}}>
                <Paper style={{padding: 32, marginBottom: 32}}>

                    {productIndex > 0 && (
                        <div style={{width: "100%"}}>
                            <Button 
                                variant="text"
                                color="secondary"
                                style={{height: "100%", textAlign: "right", float: "right"}}
                                onClick={() => {
                                    let newList = [...formData.productsDemoed];
                                    newList.splice(productIndex, 1);
                                    
                                    setFormData({...formData, productsDemoed: newList});
                                }}
                            >
                                <i className="fas fa-close grey" style={{fontSize: "2em"}}></i>
                            </Button>
                        </div>
                    )}
                    <Grid container spacing={1}>
                        {formErrors.products && !productDemoed.product && (
                            <h3 style={{color: "tomato", textAlign: "center", width: "100%"}}>Please select a product</h3>
                        )}
                        <Grid item md={11} xs={9}>
                            <Autocomplete
                                value={productDemoed.product}
                                fullWidth={true}
                                options={productList}
                                freeSolo={true}
                                onChange={(event, newValue) => {
                                    if(typeof newValue == "string") {
                                    } else {
                                        let productList = [...formData.productsDemoed];

                                        productList[productIndex].product = newValue;
                                        setFormData({...formData, productsDemoed: productList})
                                    }
                                }}
                                getOptionLabel={(product) => product.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Select the product"}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={1} xs={3} style={{paddingLeft: 8}}>
                            {formData.productsDemoed[productIndex].product && <Link to={"/products/" + formData.productsDemoed[productIndex].product.id} target="_blank" rel="noopener noreferrer">
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    style={{height: "100%"}}
                                >
                                    <i className="fas fa-eye blue"></i>
                                </Button>
                            </Link>}
                        </Grid>

                        <Grid item xs={12}>
                            <h4 style={{paddingTop: 24, marginBottom: 0}}>Feedback on the product? <span style={{fontWeight: "lighter"}}>(Share what they liked and didn't like.)</span></h4>
                        </Grid>

                        <Grid container item xs={12} style={{textAlign: "center"}}>
                            <Grid item md={2} xs={4}>
                            </Grid>
                            <Grid item md={5} xs={4}>
                                <h4>No complaints.</h4>
                            </Grid>
                            <Grid item md={5} xs={4}>
                                <h4>You might want to sit down.</h4>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignContent={"center"} alignItems={"center"}>
                            <Grid item md={2} xs={4}>
                                <h4>Features</h4>
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnFeatures = false;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnFeatures === false} />
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnFeatures = true;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnFeatures === true} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignContent={"center"} alignItems={"center"}>
                            <Grid item md={2} xs={4}>
                                <h4>Price/Value</h4>
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnPrice = false;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnPrice === false} />
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnPrice = true;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnPrice === true} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignContent={"center"} alignItems={"center"}>
                            <Grid item md={2} xs={4}>
                                <h4>Functionality</h4>
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnFunctionality = false;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnFunctionality === false} />
                            </Grid>

                            <Grid item md={5} xs={4} style={{textAlign: "center"}}>
                                <Radio onClick={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackOnFunctionality = true;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }} value={true} checked={formData.productsDemoed[productIndex].feedbackOnFunctionality === true} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                aria-label="notes"
                                label={"Product Feedback Notes"}
                                variant="outlined"
                                fullWidth={true}
                                multiline={true}
                                error={formErrors.products && !formData.productsDemoed[productIndex].feedbackNotes}
                                placeholder="Get specific, for example...
                                What features are we missing?
                                Was the price too high or too low?
                                What about the functionality did(n't) they like?"
                                value={formData.productsDemoed[productIndex].feedbackNotes}
                                onChange={(e) => {
                                    let productListCopy = [...formData.productsDemoed];

                                    productListCopy[productIndex].feedbackNotes = e.target.value;
                                    setFormData({...formData, productsDemoed: productListCopy})
                                }}
                                className="order-item-notes"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </ListItem>
        );
    }

    const _verifyForm = (): boolean => {
        let newErrors = {...DemoFormErrorInterfaceDefaults};
        let issues = false;
        if(!formData.dateOfDemo) {
            newErrors.date = true;
            issues = true;
        }

        if(formData.formType == "demo-feedback") {
            if(formData.productsDemoed.findIndex((pd) => pd.product == null || !pd.feedbackNotes) > -1) {
                newErrors.products = true;
                issues = true;
            }
    
            if(!formData.opportunityNotes) {
                newErrors.opportunityNotes = true;
                issues = true;
            }
    
            if(!formData.howCanWeHelp) {
                newErrors.howCanWeHelp = true;
                issues = true;
            }
        }

        if(formData.demoForExistingReseller == true && !formData.targetReseller) {
            newErrors.reseller = true;
            issues = true;
        }

        setFormErrors(newErrors);

        if(issues) {
            setSnackbar({
                isOpen: true,
                message: "Looks like you're missing something.",
                severity: "error",
            });
        }

        return !issues;
    }

    const _saveForm = () => {
        makeAxiosCall(
            "post",
            "demo-feedback",
            formData
        ).then(() => {
            // form saved  now what
            navigate(-1);

        }).catch((err) => console.log(err));
    }

    const _buildSubmitButton = () => {
        return (
            <Grid item xs={12} md={4}>
                <Button
                    fullWidth={true}
                    disabled={saving}
                    onClick={() => {
                        setSaving(true);
                        if(_verifyForm()) {
                            // SAVE!
                            console.log("SAVING!")
                            _saveForm();
                        } else {
                            setSaving(false);
                        }
                    }}
                    variant="contained"
                    color="primary"
                    className="btn"
                >
                    <Save style={{marginRight: 8}} /> Save Feedback
                </Button>
            </Grid>
        );
    }

    return (
        <div className="view">
            <Grid container spacing={4} justifyContent={"center"}>
                <Grid item xs={12}>
                    <h1 style={{width: "100%"}}>Reseller Interaction Form</h1>
                </Grid>
                {_buildFormSelector()}
                {_buildselectedForm()}
            </Grid>
            <Snackbar
                open={snackbar.isOpen}
                autoHideDuration={30000}
                onClose={() =>  setSnackbar({ ...snackbar, isOpen: false })}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DemoFeedbackForm;
